import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({ getAchievements }) {
  const title = "Achievements";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Achievement";
  const [open, setOpen] = useState(false);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);

  const [languageAr, setLanguagesAr] = useState({
    name: "",
    description: "",
  });
  const [languageEn, setLanguagesEn] = useState({
    name: "",
    description: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setApiErrors(null);
    setLanguagesAr({});
    setLanguagesEn({});
  };

  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"arabic description" can't be an empty field`,
        "any.required": `"arabic description" is a required field`,
      }),
     
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"english name" can't be an empty field`,
        "any.required": `"english name" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"english description" can't be an empty field`,
        "any.required": `"english description" is a required field`,
      }),
   
    });

    return schema.validate(en, { abortEarly: false });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();

    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][description]", languageAr.description);
    formData.append("languages[0][language]", "ar");
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][description]", languageEn.description);
    formData.append("languages[1][language]","en");
    if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);
      setEnErrors(valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/achievement`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );
        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getAchievements();
          setLanguagesAr({});
          setLanguagesEn({});
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };

  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setEnErrors(null);
    setArErrors(null);
  }, [languageEn]);
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
        <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Achievement
          </Typography>

          <div className="d-flex">
            <div className="me-2 w-50">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="d-flex w-100">
            <div className=" w-50">
              {/* --------------------------------------------Arabic-------------------------------------------- */}
              <p className="mb-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2 ">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arHeader" className="form-label">
                  Description
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="arHeader"
                  name="description"
                  value={languageAr.description}
                  onChange={handleChangeAr}
                />
              </div>
            
              {/*------------------------------------------End of Arabic---------------------------------------  */}
            </div>
            <div className="ms-3 w-50">
              {/*------------------------------------------English---------------------------------------------  */}

              <p className="mb-2 text-center  fw-bold">English</p>

              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="name"
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enHeader" className="form-label">
                  Description
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="enHeader"
                  name="description"
                  value={languageEn.description}
                  onChange={handleChangeEn}
                />
              </div>

              
            </div>
          </div>
          {/*------------------------------------------end of English---------------------------------------------  */}

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
