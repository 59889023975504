import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
export function useAuth() {
  return useContext(AuthContext);
}
const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("userToken"));
  const [id, setLoggedId] = useState(localStorage.getItem("loggedId"));

  const navigate = useNavigate()
  function login(token,id) {
    setToken(token);
    setLoggedId(id)
    localStorage.setItem("userToken", token);
    localStorage.setItem("loggedId", id);

    navigate("/")
}
function logout() {
    setToken(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("loggedId");

    navigate("/")
  }
  return (
    <AuthContext.Provider value={{ login, logout, token ,id}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
