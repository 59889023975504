import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Joi from "joi";
const initialRequestId={
  name: "",
  code: "",
  language: "",
  image: null,
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function UpdatePopUp({
  setUpdatePopUp,
  updatePopUp,
  country,
  id,
  setCountry,
  getCountries,
}) {
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [requestData, setRequestData] = useState({
    ...initialRequestId
  });
  useEffect(() => {
    let { name, code, language } = country;
    setRequestData({ name, code, language });
  }, [country]);

  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  const handleClose = () => {
    setUpdatePopUp(false);
    setCountry("");
    setloading(false);
    setRequestData({});
    setApiErrors([]);
    setErrorList([]);
  };

  let authToken = localStorage.getItem("userToken");

  //handle image file
  const handleFile = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
  };

  //handle changes in all inputs except image (file)
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  //post new country to API
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", requestData.name);
    formData.append("code", requestData.code);
    formData.append("language", requestData.language);
    requestData.image&&
    formData.append("image", requestData.image);

    console.log(formData);
    let valRes = validatenewCountry(requestData);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/country/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getCountries();
          setRequestData("");
        }
      } catch (error) {
        getCountries();
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log("error");
      }
    }
  };
  const validatenewCountry = (country) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
      code: Joi.string().required().messages({
        "string.empty": `"code" can't be an empty field`,
        "any.required": `"code" is a required field`,
      }),
      language: Joi.string().required().messages({
        "string.empty": `"language" can't be an empty field`,
        "any.required": `"language" is a required field`,
      }),
   
    });
    return schema.validate(country, { abortEarly: false });
  };
  useEffect(() => {
    setApiErrors([]);
    setErrorList([]);
  }, [requestData]);
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              value={requestData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Code
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="code"
              value={requestData.code}
              onChange={handleChange}
            />
          </div>
          <label htmlFor="name" className="form-label">
            Languages
            <span className="required"> * </span>
            <span className="hide">This is a Required Feild</span>
          </label>
          <div className="input-group mb-3">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="language"
              value={requestData.language}
              onChange={handleChange}
            >
              <option defaultValue>Choose...</option>
              <option value="ar">ar</option>
              <option value="en">en</option>
            </select>
            <label className="input-group-text" htmlFor="inputGroupSelect02">
              Options
            </label>
          </div>
          <div className="mb-2">
            <label htmlFor="formFile" className="form-label">
              Image
             
            </label>
            <input
              className="form-control"
              type="file"
              id="formFile"
              name="image"
              onChange={handleFile}
            />
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              onClick={handleFormSubmit}
              className="btn btn-light addBtn"
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
