import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight:'700px',
  overflowY:'scroll',
  overflowX:'hidden',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({ getQuestionTags ,setUpdatePopUp ,updatePopUp,id,questionTag}) {
  let authToken = localStorage.getItem("userToken");
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [requestData, setRequestData] = useState({
    title: "",
  });
  useEffect(() => {
    let {title}=questionTag;
    setRequestData({title})
  }, [questionTag]);
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleOpen = () => setUpdatePopUp(true);
  const handleClose = () => {
    setUpdatePopUp(false);
    setRequestData({});
    setloading(false);
  };

  useEffect(() => {
    setApiErrors(null);
  }, [requestData]);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", requestData.title);
    try {
      setloading(true);
      let { data } = await axios.post(
        `https://dashboard.kunozworld.com/api/dashboard/question-tag/${id}/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        }
      );

      if (data.message === "Successful") {
        setUpdatePopUp(false);
        setloading(false);
        getQuestionTags();
        setRequestData({});
      }
    } catch (error) {
      setApiErrors(error.response.data.errors);
      setloading(false);
    }
  };

  return (
    <div>

      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
            <div className="mb-2 w-100">
              <label htmlFor="arTitle" className="form-label">
                Title
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control"
                type="text"
                id="arTitle"
                name="title"
                placeholder={questionTag.title}
                value={requestData.title}
                onChange={handleChange}
              />
            </div>
          

        
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}
            </button>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
