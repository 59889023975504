import React from "react";
const BasicHeader = ({ title }) => {
  return (
    <>
      <div className="d-flex justify-content-between mt-3 mb-3 headerTable p-3">
        <div>
          <h6 className="mt-3 mb-3">{title}</h6>
        </div>
     
      </div>
    </>
  );
};
export default BasicHeader;
