import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function UpdatePopUp({
  setRestorePopUp,
  restorePopUp,
  id,
  getTrashCollection,
}) {
  let authToken = localStorage.getItem("userToken");
  const handleFormSubmit = async (event) => {
    try {
    let { data } = await axios.get(
        `https://dashboard.kunozworld.com/api/dashboard/match-answer/${id}/restore`,

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        }
      );

      if (data.message === "Successful") {
        setRestorePopUp(false);
        getTrashCollection();
      }
    } catch (error) {
      getTrashCollection();
      console.log("error");
    }
  };
  const handleClose = () => {
    setRestorePopUp(false);
  };

  return (
    <div>
      <Modal
        open={restorePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            Restore Row
          </Typography>

          <div className="closeBtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              onClick={handleFormSubmit}
              className="btn btn-light addBtn"
            >
              Restore
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
