import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ArabicModal({ ar, arabicPopUp, setArabicPopUp }) {
  const handleClose = () => {
    setArabicPopUp(false);
  };
  return (
    <div>
      <Modal
        open={arabicPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            View Arabic
          </Typography>
          <div className="d-flex justify-content-center mb-2">
            <div className="me-3">
              <img src={ar ? ar.image_one : ""} className="achieveImg" alt="" />
            </div>
            <div className="">
              <img src={ar ? ar.image_two : ""} className="achieveImg" alt="" />
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="id" className="form-label">
              Name
            </label>
            <input
              className="form-control"
              type="text"
              id="id"
              name="id"
              placeholder={ar && ar.name}
              disabled
            />
          </div>
  
          <div className="mb-2">
            <label htmlFor="id" className="form-label">
              Language
            </label>
            <input
              className="form-control"
              type="text"
              id="id"
              name="id"
              placeholder={ar && ar.language}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="id" className="form-label">
              Url
            </label>
            <input
              className="form-control"
              type="text"
              id="id"
              name="id"
              value={ar && ar.url}
            />
          </div>

      

          <div className="closeBtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} className="backBtn me-2">
              Back
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
