import React, { useState,useEffect } from "react";
import SideBar from "./components/SideBar/SideBar";
import TopBar from "./components/TopBar/TopBar";
import "./app.css";
import Home from "./components/Home/Home";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import CityTable from "./components/City/CityTable/CityTable";
import AboutUs from "./components/AboutUs/AboutUs/AboutUs";
import Login from "./components/Login/Login";
import GovernmentTable from "./components/Government/GovernmentTable/GovernmentTable";
import CountryTable from "./components/Country/CountryTable/CountryTable";
import { Menu } from "@mui/icons-material";
import LanguagesTable from "./components/Languages/LanguagesTable/LanguagesTable";
import ContactUsTable from "./components/ContactUs/ContactUsTable/ContactUsTable";
import { useAuth } from "./providers/AuthProvider";
import SubscriptionTable from "./components/Subscription/SubscriptionTable/SubscriptionTable";
import McqQuestionsTable from "./components/McqQuestions/McqQuestionsTable/McqQuestionsTable";
import McqAnswersTable from "./components/McqAnswers/McqAnswersTable/McqAnswersTable";
import TrueAndFalseTable from "./components/TrueAndFalse/TrueAndFalseTable/TrueAndFalseTable";
import MatchQuestions from "./components/MatchQuestions/MatchQuestionTable/MatchQuestions";
import MatchAnswers from "./components/MatchAnswers/MatchAnswersTable/MatchAnswers";
import Activity from "./components/Activity/ActivityTable/Activity";
import Quiz from "./components/Quiz/QuizTable/Quiz";
// import Registration from "./components/Registration/Registration";
import User from "./components/User/UsersTable/User";
import SubUser from "./components/SubUser/SubUserTable/SubUser";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import AvatarTable from "./components/Avatar/AvatarTable/AvatarTable";
import SkinTable from "./components/Skin/SkinTable/SkinTable";
import Certifications from "./components/Certifications/CertificationsTable/Certifications";
import AchievementTable from "./components/Achievement/AchievementTable/AchievementTable";
import AchievementImages from "./components/AchievementImages/AchievementImagesTable/AchievementImages";
import AgeTable from "./components/Age/AgeTable/AgeTable";
import AgeGroupTable from "./components/AgeGroup/AgeGroupTable/AgeGroupTable";
import SkillTable from "./components/Skill/SkillTable/SkillTable";
// import Test from './components/test/Test'
import Theme from "./theme/Theme";
import SoundTable from "./components/Sound/SoundTable/SoundTable";
import HeroTable from "./components/Hero/HeroTable/HeroTable";
import SubjectTable from "./components/Subject/SubjectTable/SubjectTable";
import SubSubject from "./components/SubSubject/SubSubjectTable/SubSubject";
import PackageTable from "./components/Package/PackageTable/PackageTable";
import StoreTable from "./components/Store/StoreTable/StoreTable";
import BodySuitTable from "./components/BodySuit/BodySuitTable/BodySuitTable";
import HumanPartTable from "./components/HumanPart/HumanPartTable/HumanPartTable";
import LessonTypeTable from "./components/LessonType/LessonTypeTable/LessonTypeTable";
import GamesTable from "./components/Games/GamesTable/GamesTable";
import VideosTable from "./components/VideosLesson/VideosTable/VideosTable";
import Profile from "./components/Profile/Profile/Profile";
import AccessoryTable from "./components/Accessory/AccessoryTable/AccessoryTable";
import QuizType from "./components/QuizType/QuizTable/QuizType";
import RolesTable from "./components/Roles/RolesTable/RolesTable";
import PlayTimeTable from "./components/PlayTime/PlayTimeTable/PlayTimeTable";
import BasicsTable from "./components/Basics/BasicsTable/BasicsTable"
import QuestionTagsTable from './components/QuestionTags/QuestionTagsTable/QuestionTagsTable'
const Layout = () => {
  const [isOpen, setisOpen] = useState(true);
  const handleSideBarToggle = () => {
    setisOpen(!isOpen);
  };

  useEffect(()=>{
    function handleResize() {
      if(window.innerWidth<=600){
        setisOpen(false);
      }else{
        setisOpen(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  },[])

  return (
    <div className="d-flex">
      <SideBar isOpen={isOpen} />
      <div className="container ">
        <div className="w-100 d-flex justify-content-between flex-row-reverse">
          <TopBar />
          <div className="menuIcon" onClick={handleSideBarToggle}>
            <Menu />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};
const App = () => {
  const { token } = useAuth();
  return (
    <Theme>
      {/* <Test/> */}
      <Routes>
        {token ? (
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            {/* <Route path="home" element={<Home />} /> */}
            <Route path="supscription" element={<SubscriptionTable />} />
            {/* <Route path="registration" element={<Registration />} /> */}
            <Route path="user" element={<User />} />
            <Route path="subuser" element={<SubUser />} />
            <Route path="socailmedia" element={<SocialMedia />} />
            <Route path="country" element={<CountryTable />} />
            <Route path="city" element={<CityTable />} />
            <Route path="government" element={<GovernmentTable />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="languages" element={<LanguagesTable />} />
            <Route path="contactus" element={<ContactUsTable />} />
            <Route path="trueandfalse" element={<TrueAndFalseTable />} />
            <Route path="mcqquestions" element={<McqQuestionsTable />} />
            <Route path="mcqanswers" element={<McqAnswersTable />} />
            <Route path="matchquestions" element={<MatchQuestions />} />
            <Route path="matchanswers" element={<MatchAnswers />} />
            <Route path="activity" element={<Activity />} />
            {/* <Route path="assignment" element={<Assignment />} /> */}
            <Route path="quiztype" element={<QuizType />} />
            <Route path="quiz" element={<Quiz />} />
            <Route path="avatar" element={<AvatarTable />} />
            <Route path="skins" element={<SkinTable />} />
            <Route path="certifications" element={<Certifications />} />
            <Route path="achievement" element={<AchievementTable />} />
            <Route path="achievementimages" element={<AchievementImages />} />
            <Route path="age" element={<AgeTable />} />
            <Route path="agegroup" element={<AgeGroupTable />} />
            <Route path="skill" element={<SkillTable />} />
            <Route path="sounds" element={<SoundTable />} />
            <Route path="hero" element={<HeroTable />} />
            <Route path="subject" element={<SubjectTable />} />
            <Route path="subsubject" element={<SubSubject />} />
            <Route path="package" element={<PackageTable />} />
            <Route path="playtime" element={<PlayTimeTable />} />
            <Route path="store" element={<StoreTable />} />
            <Route path="bodysuit" element={<BodySuitTable />} />
            <Route path="humanpart" element={<HumanPartTable />} />
            <Route path="lessontype" element={<LessonTypeTable />} />
            <Route path="games" element={<GamesTable />} />
            <Route path="videos" element={<VideosTable />} />
            <Route path="profile" element={<Profile />} />
            <Route path="accessory" element={<AccessoryTable />} />
            <Route path="roles" element={<RolesTable />} />
            <Route path="basics" element={<BasicsTable />} />
            <Route path="questiontags" element={<QuestionTagsTable />} />

          </Route>
        ) : (
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route index element={<Navigate to="/login" />} />
          </Route>
        )}
      </Routes>
    </Theme>
  );
};

export default App;
