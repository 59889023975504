import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import CircularProgress from "@mui/material/CircularProgress";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({ getCertifications }) {
  const title = "Certifications";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Certificate";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [languageAr, setLanguagesAr] = useState({
    title_one: "",
    title_two: "",
    description: "",
  });
  const [languageEn, setLanguagesEn] = useState({
    title_one: "",
    title_two: "",
    description: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setApiErrors(null);
    setErrorList(null);
    setRequestData({});
    setLanguagesAr({});
    setLanguagesEn({});
  };

  const [requestData, setRequestData] = useState({
    image_one: null,
    image_two: null,
    image_three: null,
    min_point: 1,
    max_point: 2,
  });
  const handleImageOne = (e) => {
    setRequestData({
      ...requestData,
      image_one: e.target.files[0],
    });
  };
  const handleImagTwo = (e) => {
    setRequestData({
      ...requestData,
      image_two: e.target.files[0],
    });
  };
  const handleImagThree = (e) => {
    setRequestData({
      ...requestData,
      image_three: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
      title_one: Joi.string().required().messages({
        "string.empty": `"arabic title 1" can't be an empty field`,
        "any.required": `"arabic title 1" is a required field`,
      }),
      title_two: Joi.string().required().messages({
        "string.empty": `"arabic title 2" can't be an empty field`,
        "any.required": `"arabic title 2" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"arabic description" can't be an empty field`,
        "any.required": `"arabic description" is a required field`,
      }),
  
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      title_one: Joi.string().required().messages({
        "string.empty": `"english title 1" can't be an empty field`,
        "any.required": `"english title 1" is a required field`,
      }),
      title_two: Joi.string().required().messages({
        "string.empty": `"english title 2" can't be an empty field`,
        "any.required": `"english title 2" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"english description" can't be an empty field`,
        "any.required": `"english description" is a required field`,
      }),
 
    });

    return schema.validate(en, { abortEarly: false });
  };
  const validateCertificate = (requestData) => {
    let schema = Joi.object({
      image_one: Joi.object().required().messages({
        "object.base": `"image 1" can't be an empty field`,
      }),
      image_two: Joi.object().required().messages({
        "object.base": `"image 2" can't be an empty field`,
      }),
      image_three: Joi.object().required().messages({
        "object.base": `"image 3" can't be an empty field`,
      }),
      min_point: Joi.optional().allow(null),
      max_point: Joi.optional().allow(null),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateCertificate(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);

    const formData = new FormData();
    formData.append("image_one", requestData.image_one);
    formData.append("image_two", requestData.image_two);
    formData.append("image_three", requestData.image_three);
    requestData.min_point&&
    formData.append("min_point", requestData.min_point);
    requestData.max_point&&
    formData.append("max_point", requestData.max_point);
    formData.append("languages[0][title_one]", languageAr.title_one);
    formData.append("languages[0][title_two]", languageAr.title_two);
    formData.append("languages[0][description]", languageAr.description);
    formData.append("languages[0][language]", 'ar');
    formData.append("languages[1][title_one]", languageEn.title_one);
    formData.append("languages[1][title_two]", languageEn.title_two);
    formData.append("languages[1][description]", languageEn.description);
    formData.append("languages[1][language]", 'en');
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);

      setEnErrors(valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/certificate`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );
        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getCertifications();
          setRequestData({});
          setLanguagesAr({});
          setLanguagesEn({});
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setEnErrors(null);
    setArErrors(null);
  }, [languageEn]);
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Certificate
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex justify-content-between w-100">
            <div className="w-50 me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="d-flex w-100">
            <div className="w-50">
              {/* --------------------------------------------Arabic-------------------------------------------- */}
              <p className="mb-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Title One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="title_one"
                  value={languageAr.title_one}
                  onChange={handleChangeAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arHeader" className="form-label">
                  Title Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arHeader"
                  name="title_two"
                  value={languageAr.title_two}
                  onChange={handleChangeAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arHeader" className="form-label">
                  Description
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="arHeader"
                  name="description"
                  value={languageAr.description}
                  onChange={handleChangeAr}
                />
              </div>

              
              {/*------------------------------------------End of Arabic---------------------------------------  */}
            </div>
            <div className="ms-3 w-50">
              {/*------------------------------------------English---------------------------------------------  */}

              <p className="mb-2 text-center  fw-bold">English</p>

              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Title One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="title_one"
                  value={languageEn.title_one}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Title Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="title_two"
                  value={languageEn.title_two}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arHeader" className="form-label">
                  Description
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="arHeader"
                  name="description"
                  value={languageEn.description}
                  onChange={handleChangeEn}
                />
              </div>
             
            </div>
          </div>
          {/*------------------------------------------end of English---------------------------------------------  */}

          <div className="mb-2">
            <label htmlFor="image" className="form-label">
              Image One
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="image"
              name="image_one"
              onChange={handleImageOne}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="image" className="form-label">
              Image Two
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="image"
              name="image_two"
              onChange={handleImagTwo}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="image" className="form-label">
              Image Three
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="image"
              name="image_three"
              onChange={handleImagThree}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Min points
              <span className="required"> </span>
            </label>
            <input
              className="form-control"
              type="text"
              id="degree"
              name="min_point"
              value={requestData.min_point}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Max points
              <span className="required"> </span>
            </label>
            <input
              className="form-control"
              type="text"
              id="degree"
              name="max_point"
              value={requestData.max_point}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
