
import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import Paper from "@mui/material/Paper";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import AddHumanPartModal from '../AddHumanPartModal/AddHumanPartModal'

const HumanPartTable = () => {
  let authToken = localStorage.getItem("userToken");

  const [humanParts, setHumanParts] = useState([]);
  const [humanPart, setHumanPart] = useState([]);
  const [loading, setloading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [id, setId] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
//   const handleView = () => {
//     setViewPopUp(!viewPopUp);
//   };
    const handleUpdate = () => {
      setUpdatePopUp(!updatePopUp);
    };

  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/human-part/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setHumanPart(data.data[0]);
    
  };

  const getHumanParts = async () => {
    let { data } = await axios
      .get(`https://dashboard.kunozworld.com/api/dashboard/human-part`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setHumanParts(data.data);
  };
  useEffect(() => {
    getHumanParts();
  }, []);
 

  const deleteRow = (id) => {
    const temp = [...humanParts];
    axios.delete(
      `https://dashboard.kunozworld.com/api/dashboard/human-part/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setHumanParts(temp);
    getHumanParts();
    setDeletePopUp(!deletePopUp);
  };
  return (
    <div>
        <AddHumanPartModal  getHumanParts={getHumanParts}/>
        <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getHumanParts={getHumanParts}
        deleteRow={deleteRow}
        id={id}
      />
        <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        humanPart={humanPart}
        setHumanPart={setHumanPart}
        id={id}
        getHumanParts={getHumanParts}
     
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
 
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Name
                </TableCell>
        
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {humanParts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                  
                        <EditIcon
                          className="editIcon "
                            onClick={() => {
                              setId(row.id);
                              viewBeforeUpdate(row.id);
                              handleUpdate();
                            }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={humanParts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default HumanPartTable;
