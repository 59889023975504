import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function ArabicModal({ en, englishPopUp, setEnglishPopUp }) {
  if (!en) return;
  const handleClose = () => {
    setEnglishPopUp(false);
   
  };
  return (
    <div>
      <Modal
        open={englishPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            View English
          </Typography>

        
          <div className="d-flex justify-content-center mb-2">
            <div>
              <img src={en ? en.image : ""} className="achieveImg" alt="" />
            </div>
            
          </div>
          <div className="mb-2">
            <label htmlFor="id" className="form-label">
              Language
            </label>
            <input
              className="form-control"
              type="text"
              id="id"
              name="id"
              placeholder={en && en.language}
              disabled
            />
          </div>

          <div className="mb-2">
            <label htmlFor="id" className="form-label">
              Title
            </label>
            <input
              className="form-control"
              type="text"
              id="id"
              name="id"
              placeholder={en && en.title}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="id" className="form-label">
              Description
            </label>
            <textarea
              className="form-control"
              type="text"
              id="id"
              name="id"
              placeholder={en && en.description}
              disabled
            />
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} className="backBtn me-2">
              Back
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
