import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Joi from "joi";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialRequestId = {
  name: "",
  email: "",
  country_id: "",
  phone: "",
  password: "",
  password_confirmation: "",
  birthdate: "",
  role_id:null,
  avatar: null,
  active:0,
};
export default function UpdatePopUp({
  setUpdatePopUp,
  updatePopUp,
  user,
  id,
  setUser,
  getUsers,
}) {
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [value, setValue] = React.useState("");
  const [countries, setCountries] = useState([]);
  const [requestData, setRequestData] = useState({
    ...initialRequestId
  });
  const handleImage = (e) => {
    setRequestData({ ...requestData, avatar: e.target.files[0] });
  };
  const [passwordType, setPasswordType] = useState("password");
  const [passwordConfirmType, setPasswordConfirmType] = useState("password");
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  console.log(user)
  useEffect(() => {
    let { name, email, phone,birthdate,avatar ,UserRoles,country_id}
    = user;
    setRequestData({ name, email, phone,birthdate,avatar ,role_id:UserRoles?.id,country_id});
  }, [user]);
  const handleClose = () => {
    setUpdatePopUp(false);
    setApiErrors(null);
    setErrorList(null);
    setRequestData({...initialRequestId});
  };
  const togglePasswordConfirm = () => {
    if (passwordConfirmType === "password") {
      setPasswordConfirmType("text");
      return;
    }
    setPasswordConfirmType("password");
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  let authToken = localStorage.getItem("userToken");
  const handleChangeRadio = (event) => {
    setValue(event.target.value);
    if (event.target.checked) {
      setRequestData({
        ...requestData,
        [event.target.name]: event.target.value,
      });
    }
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  //get countries Id
  const getCountries = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/country",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCountries(data.data);
  };
  let countriesIds = countries.map((country) => ({
    id: country.id,
    name: country.name,
  }));
  useEffect(() => {
    getCountries();
  }, []);
  //end of countries Id
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", requestData.name);
    formData.append("email", requestData.email);
    formData.append("country_id", requestData.country_id);
    formData.append("phone", requestData.phone);
    formData.append("password", requestData.password);
    formData.append("password_confirmation", requestData.password_confirmation);
    formData.append("birthdate", requestData.birthdate);
    formData.append("role_id", requestData.role_id);
    requestData.active&&
    formData.append("active", requestData.active);
    requestData.avatar && formData.append("avatar", requestData.avatar);
    let valRes = validateRegister(requestData);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/user/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getUsers();
          setRequestData("");
        }
      } catch (error) {
        getUsers();
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log("error");
      }
    }
  };
  const validateRegister = (user) => {
    let imgTest = requestData.avatar?.toString()===requestData.avatar;
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: false}})
        .messages({
          "string.base": `"email" should be valid`,
          "string.empty": `"email" can't be an empty field`,
          "any.required": `"email" is a required field`,
        }),
      country_id: Joi.number().required().messages({
        "number.empty": `"country_id" can't be an empty field`,
        "any.required": `"country_id" is a required field`,
      }),
      phone: Joi.string()
        .min(10)
        .max(15)
        .pattern(/^[0-9]+$/)
        .required()
        .messages({
          "number.empty": `"number" can't be an empty field`,
          "any.required": `"number" is a required field`,
        }),
      password: Joi.string()
        .min(6)
        .max(15)
        .regex(new RegExp("^.{6,15}$"))
        .messages({
          "string.base": `"password" should be valid`,
          "string.empty": `"password" can't be an empty field`,
          "any.required": `"password" is a required field`,
          "string.pattern.base": `"password" pattern doesn't match this password`,
        }),
      password_confirmation: Joi.string()
        .min(6)
        .max(15)
        .regex(new RegExp("^.{6,15}$"))
        .messages({
          "string.base": `"password confirmation" should be valid`,
          "string.empty": `"password confirmation" can't be an empty field`,
          "any.required": `"password confirmation" is a required field`,
          "string.pattern.base": `"password confirmation" pattern doesn't match this password`,
        }),
      birthdate: Joi.string().required().messages({
        "string.base": `"birthdate" should be valid`,
        "string.empty": `"birthdate" can't be an empty field`,
      }),
      role_id: Joi.number().required().messages({
        "number.empty": `"role_id" can't be an empty field`,
        "any.required": `"role_id" is a required field`,
      }),
  avatar: imgTest ? Joi.string().optional().allow(null) : Joi.object().optional().allow(null),      
  active: Joi.optional().allow(null),
    });
    return schema.validate(user, { abortEarly: false });
  };
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder={user.name}
              value={requestData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Email
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="email"
              placeholder={user.email}
              value={requestData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Phone
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="phone"
              placeholder={user.phone}
              value={requestData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="mb-2 ">
            <label htmlFor="password" className="form-label">
              Password
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <div className="position-relative">
              <input
                className="form-control formReg"
                type={passwordType}
                id="password"
                name="password"
                placeholder={user.password}
                value={requestData.password}
                onChange={handleChange}
              />
              {passwordType === "password" ? (
                <VisibilityIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePassword}
                />
              ) : (
                <VisibilityOffIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePassword}
                />
              )}
            </div>
          </div>

          <div className="mb-2 position-relative">
            <label htmlFor="password_confirmation" className="form-label">
              Password Confirmation
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <div className="position-relative">
              <input
                className="form-control formReg bg-none position-relative"
                type={passwordConfirmType}
                id="password_confirmation"
                name="password_confirmation"
                placeholder={user.passwordConfirmType}
                value={requestData.passwordConfirmType}
                onChange={handleChange}
              />
              {passwordConfirmType === "password" ? (
                <VisibilityIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePasswordConfirm}
                />
              ) : (
                <VisibilityOffIcon
                  className="position-absolute end-0 visibile"
                  onClick={togglePasswordConfirm}
                />
              )}
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="arVideo" className="form-label">
              Country Id
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={requestData.country_id}
                onChange={handleChange}
                name="country_id"
                MenuProps={MenuProps}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                className="customSelect"
              >
                {countriesIds.map((countryId) => (
                  <MenuItem key={countryId.id} value={countryId.id}>
                    {countryId.id} {`[ ${countryId.name} ]`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="mb-2">
            <label htmlFor="birthdate" className="form-label">
              Birthdate
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>

            <input
              type="date"
              id="birthdate"
              name="birthdate"
              className="form-control formReg bg-none"
              placeholder={user.birthdate}
              value={requestData.birthdate}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mb-2">
            <label htmlFor="avatar" className="form-label">
              Avatar
            </label>
            <input
              type="file"
              id="avatar"
              className="form-control"
              name="avatar"
              onChange={handleImage}
            ></input>
          </div>
          <label htmlFor="avatar" className="form-label">
                Active
              </label>
          <div className="input-group mb-2">
       
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="active"
              value={requestData.active}
              onChange={handleChange}
            >
              <option defaultValue>Choose...</option>
              <option value="0">Inactive</option>
              <option value="1">Active</option>
            </select>
            <label className="input-group-text" htmlFor="inputGroupSelect02">
              Options
            </label>
          </div>
          <FormControl component="fieldset" className="mt-2 ms-1">
            <RadioGroup aria-label="gender" name="gender1" value={value}>
              <div className="m-auto ">
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Admin"
                  name="role_id"
                  onChange={handleChangeRadio}
                />
                <FormControlLabel
                  value="2"
                  name="role_id"
                  control={<Radio />}
                  label="Parent"
                  onChange={handleChangeRadio}
                />
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </div>
            </RadioGroup>
          </FormControl>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              onClick={handleFormSubmit}
              className="btn btn-light addBtn"
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
