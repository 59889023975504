import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Joi from "joi";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initialRequestId = {
  points: 0,
  image_one: null,
  image_two: null,
  achievement_id: null,
};
export default function UpdatePopUp({
  setUpdatePopUp,
  updatePopUp,
  achievement,
  setAchievementImage,
  id,
  achievementImage,
  getAchievementImage,
}) {
  const [open, setOpen] = React.useState(false);
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [achievements, setAchievements] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdatePopUp(false);
    setRequestData({...initialRequestId});
    setApiErrors(null);
    setErrorList(null);
  };

  const [requestData, setRequestData] = useState({
    ...initialRequestId,
  });
  useEffect(() => {
    if(achievementImage){
      let { points,achievement } = achievementImage;
    setRequestData({ points ,achievement_id:achievement?.id||0});
    }
  }, [achievementImage]);

  const handleImageOne = (e) => {
    setRequestData({
      ...requestData,
      image_one: e.target.files[0],
    });
  };
  const handleImagTwo = (e) => {
    setRequestData({
      ...requestData,
      image_two: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  let authToken = localStorage.getItem("userToken");
  const validaeAchievementImg = (achievement) => {
    console.log(achievement);
    let schema = Joi.object({
      points: Joi.optional().allow(null),
      achievement_id: Joi.number().required().messages({
        "number.empty": `"achievement_id" can't be an empty field`,
        "any.required": `"achievement_id" is a required field`,
      }),
    });
    return schema.validate(achievement, { abortEarly: false });
  };
  //get acheivement Id
  const getAchievementIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/achievement",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAchievements(data.data);
  };
  let achievementIds = achievements.map((achievement) => ({
    id: achievement.id,
    name: achievement.name,
  }));
  useEffect(() => {
    getAchievementIds();
  }, []);
  //end of acheivement
  if (!achievement || !achievementImage) return;

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validaeAchievementImg(requestData);
    const formData = new FormData();
    requestData.points && formData.append("points", requestData.points);
    requestData.image_one&&
    formData.append("image_one", requestData.image_one);
    requestData.image_two&&
    formData.append("image_two", requestData.image_two);
    formData.append("achievement_id", requestData.achievement_id);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/achievementimage/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getAchievementImage();
          setRequestData("");
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };

  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Points
              <span className="required"> </span>
            </label>
            <input
              className="form-control"
              type="text"
              id="degree"
              name="points"
              value={requestData.points}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="image" className="form-label">
              Image One
             
            </label>
            <input
              className="form-control"
              type="file"
              id="image"
              name="image_one"
              onChange={handleImageOne}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="image" className="form-label">
              Image Two
             
            </label>
            <input
              className="form-control"
              type="file"
              id="image"
              name="image_two"
              onChange={handleImagTwo}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Achievement
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={requestData.achievement_id}
                onChange={handleChange}
                name="achievement_id"
                MenuProps={MenuProps}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                className="customSelect"
              >
                {achievementIds.map((acheivement) => (
                  <MenuItem key={acheivement.id} value={acheivement.id}>
                    {acheivement.id}{" "}
                    {`[${acheivement.name ? acheivement.name : "No Title"}]`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
