import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


export default function DeletePopUp({ deletePopUp, setDeletePopUp ,id,deleteRow}) {

  const handleClose = () => {
    setDeletePopUp(false);
  };
  return (
    <div>
      <Modal
        open={deletePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            Delete Confirmation
          </Typography>
          <Typography id="modal-modal-description"
           
            className=" mb-2">
            Are you sure you want to delete this Role ? If this was by accident
            please press Back
          </Typography>
          
          <div className="closeBtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center"> 
          <Button onClick={handleClose} className='backBtn me-2'>Back</Button>
          <Button onClick={()=>{
           deleteRow(id)
          }} autoFocus className="text-danger delete">Delete</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
