import React from "react";
const Home = () => {
  return <div className="d-flex justify-content-center align-items-center w-100 h-75 ddd mt-4"><p className="trick">
    DASHBOARD HOME
  </p>

    
  </div>;
};

export default Home;
