import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./SideBar.css";

import {
  LineStyle,
  Edit,
  People,
  Payment,
  FilterList,
  LocationOn,
  AutoAwesome,
  Forum,
  Quiz,
  Campaign,
  Language,
  Person,
  SupportAgent,
  Logout,
  ArrowForwardIos,

} from "@mui/icons-material";
import WidgetsIcon from '@mui/icons-material/Widgets';import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import img from "../../images/Group.png";
import { useAuth } from "../../providers/AuthProvider";
const SideBar = ({ isOpen }) => {
  const [isActivegeo, setActivegeo] = useState(false);
  const [openedGeo, setOpenedGeo] = useState(false);
  const [isActiveTest, setActiveTest] = useState(false);
  const [openedTest, setOpenedTest] = useState(false);
  const [isActiveUsers, setActiveUsers] = useState(false);
  const [openedUsers, setOpenedUsers] = useState(false);
  const [isActiveNeeds, setActiveNeeds] = useState(false);
  const [openedNeeds, setOpenedNeeds] = useState(false);
  const [isActiveContent, setActiveContent] = useState(false);
  const [openedContent, setOpenedContent] = useState(false);
  const [isActiveStore, setActiveStore] = useState(false);
  const [openedStore, setOpenedStore] = useState(false);
  const { logout } = useAuth();
  const handleToggleGeo = () => {
    setActivegeo(!isActivegeo);
  };
  const handleArrowGeo = () => {
    setOpenedGeo(!openedGeo);
  };
  const handleToggleTest = () => {
    setActiveTest(!isActiveTest);
  };
  const handleArrowTest = () => {
    setOpenedTest(!openedTest);
  };
  const handleToggleUsers = () => {
    setActiveUsers(!isActiveUsers);
  };
  const handleArrowUsers = () => {
    setOpenedUsers(!openedUsers);
  };
  const handleToggleNeeds = () => {
    setActiveNeeds(!isActiveNeeds);
  };
  const handleArrowNeeds = () => {
    setOpenedNeeds(!openedNeeds);
  };
  const handleToggleContent = () => {
    setActiveContent(!isActiveContent);
  };
  const handleArrowContnet = () => {
    setOpenedContent(!openedContent);
  };
  const handleToggleStore = () => {
    setActiveStore(!isActiveStore);
  };
  const handleArrowStore = () => {
    setOpenedStore(!openedStore);
  };

  return (
    <div className="">
      <div
        className={`sideBar d-block  ${
          isOpen === true ? "d-block" : "d-none"
        } `}
      >
        <div className="sideBarWrapper">
          <div className="d-flex justify-content-center w-100">
            <img src={img} alt="" className="imgSide" />
          </div>
          <ul className="sideBarList ms-3 mt-4">
            <li className={`sideBarListItem `}>
              <NavLink to="/">
                <LineStyle className="me-2  activeIcon" />
                Dashboard
              </NavLink>
            </li>
            <li className={`sideBarListItem  `}>
            <NavLink to="/roles">

              <Edit className="me-2 activeIcon" />
              Roles
              </NavLink>

            </li>
            <li className={`sideBarListItem  `}>
            <NavLink to="/basics">

              <WidgetsIcon className="me-2 activeIcon" />
              Basics
              </NavLink>

            </li>
            {/* <li className={`sideBarListItem  `}>
              <NavLink to="/registration">
                <AppRegistrationIcon className="activeIcon me-2 " />
                Registration
              </NavLink>
            </li> */}
            <li className="sideBarListItem">
              <People
                className="me-2 "
                onClick={() => {
                  handleArrowUsers();
                  handleToggleUsers();
                }}
              />
              <span
                onClick={() => {
                  handleArrowUsers();
                  handleToggleUsers();
                }}
              >
                Users
              </span>
              <ArrowForwardIos
                onClick={() => {
                  handleArrowUsers();
                  handleToggleUsers();
                }}
                className="forward"
                style={{
                  transform: openedUsers ? "rotate(90deg)" : "",
                  transition: "all 0.2s ease-in",
                }}
              />
              <ul
                className={` subList collapse ${
                  isActiveUsers ? "d-block" : "collapse"
                }`}
                id="geography"
              >
                <li>
                  <NavLink to="/user">Users</NavLink>
                </li>
                <li>
                  <NavLink to="/subuser" className="">
                    Sub-Users
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/socailmedia" className="">
                    Social Media
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/age" className="">
                    Ages
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/agegroup" className="">
                    Age Groups
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sideBarListItem">
              <NavLink to="/supscription">
                <Payment className="me-2 activeIcon " />
                Subscriptions
              </NavLink>
            </li>
            {/* <li className="sideBarListItem">
              <FilterList className="me-2 " />
              Content Mangement
            </li> */}
            <li className="sideBarListItem">
              <FilterList
                className="me-2"
                onClick={() => {
                  handleArrowContnet();
                  handleToggleContent();
                }}
              />
              <span
                onClick={() => {
                  handleArrowContnet();
                  handleToggleContent();
                }}
              >
                Content Mangement
              </span>
              <ArrowForwardIos
                onClick={() => {
                  handleArrowContnet();
                  handleToggleContent();
                }}
                className="forward"
                style={{
                  transform: openedContent ? "rotate(90deg)" : "",
                  transition: "all 0.2s ease-in",
                }}
              />
              <ul
                className={` subList collapse ${
                  isActiveContent ? "d-block" : "collapse"
                }`}
                id="geography"
              >
            <li>
                  <NavLink to="/activity" className="">
                    Activities
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sounds">Sounds</NavLink>
                </li>

                <li>
                  <NavLink to="/hero" className="">
                  Heros
                  </NavLink>
                </li>
                
                <li>
                  <NavLink to="/subject" className="">
                    Subjects
                  </NavLink>
                </li>  <li>
                  <NavLink to="/subsubject" className="">
                    Sub-Subjects
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/lessontype" className="">
                    Lesson Types
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/games" className="">
                    Lesson Games
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/videos" className="">
                    Lesson Videos
                  </NavLink>
                </li>
            
              </ul>
            </li>
            <li className="sideBarListItem">
              <LocationOn
                className="me-2 "
                onClick={() => {
                  handleArrowGeo();
                  handleToggleGeo();
                }}
              />
              <span
                onClick={() => {
                  handleArrowGeo();
                  handleToggleGeo();
                }}
              >
                Geography
              </span>
              <ArrowForwardIos
                onClick={() => {
                  handleArrowGeo();
                  handleToggleGeo();
                }}
                className="forward"
                style={{
                  transform: openedGeo ? "rotate(90deg)" : "",
                  transition: "all 0.2s ease-in",
                }}
              />
              <ul
                className={` subList collapse ${
                  isActivegeo ? "d-block" : "collapse"
                }`}
                id="geography"
              >
                <li>
                  <NavLink to="/country">Countries</NavLink>
                </li>
                <li>
                  <NavLink to="/city" className="">
                    Cities
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/government" className="">
                    Governments
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sideBarListItem">
              <LocalGroceryStoreIcon
                className="me-2 "
                onClick={() => {
                  handleArrowStore();
                  handleToggleStore();
                }}
              />
              <span
                onClick={() => {
                  handleArrowStore();
                  handleToggleStore();
                }}
              >
                Market
              </span>
              <ArrowForwardIos
                onClick={() => {
                  handleArrowStore();
                  handleToggleStore();
                }}
                className="forward"
                style={{
                  transform: openedStore ? "rotate(90deg)" : "",
                  transition: "all 0.2s ease-in",
                }}
              />
              <ul
                className={` subList collapse ${
                  isActiveStore ? "d-block" : "collapse"
                }`}
                id="geography"
              >
             
                <li>
                  <NavLink to="/store" className="">
                    Stores
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/package" className="">
                    Packages
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/accessory" className="">
                    Accessories
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sideBarListItem">
              <AutoAwesome
                className="me-2 "
                onClick={() => {
                  handleArrowNeeds();
                  handleToggleNeeds();
                }}
              />
              <span
                onClick={() => {
                  handleArrowNeeds();
                  handleToggleNeeds();
                }}
              >
                Needs
              </span>
              <ArrowForwardIos
                onClick={() => {
                  handleArrowNeeds();
                  handleToggleNeeds();
                }}
                className="forward"
                style={{
                  transform: openedNeeds ? "rotate(90deg)" : "",
                  transition: "all 0.2s ease-in",
                }}
              />
              <ul
                className={` subList collapse ${
                  isActiveNeeds ? "d-block" : "collapse"
                }`}
                id="geography"
              >
                <li>
                  <NavLink to="/avatar">Avatars</NavLink>
                </li>
                <li>
                  <NavLink to="/skins" className="">
                    Skins
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/playtime" className="">
                    Play Times
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/skill" className="">
                    Skills
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/certifications" className="">
                    Certifications
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/achievement" className="">
                    Achievements
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/achievementimages" className="">
                    Achievement Images
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/bodysuit" className="">
                    Body Suits
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/humanpart" className="">
                    Human Parts
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sideBarListItem">
              <a  >
                <Forum className="me-2" />
                Chat
                <ArrowForwardIos className="forward" />
              </a>
            </li>
            <li className="sideBarListItem">
              <Quiz
                className="me-2 "
                onClick={() => {
                  handleArrowTest();
                  handleToggleTest();
                }}
              />
              <span
                onClick={() => {
                  handleArrowTest();
                  handleToggleTest();
                }}
              >
                Test
              </span>
              <ArrowForwardIos
                onClick={() => {
                  handleArrowTest();
                  handleToggleTest();
                }}
                className="forward"
                style={{
                  transform: openedTest ? "rotate(90deg)" : "",
                  transition: "all 0.2s ease-in",
                }}
              />
              <ul
                className={` subList collapse ${
                  isActiveTest ? "d-block" : "collapse"
                }`}
                id="geography"
              >
                  <li>
                  <NavLink to="/questiontags">Question Tags</NavLink>
                </li>
                <li>
                  <NavLink to="/quiztype">Quiz Types</NavLink>
                </li>
                <li>
                  <NavLink to="/quiz">Quizs</NavLink>
                </li>
                <li>
                  <NavLink to="/trueandfalse">True & False</NavLink>
                </li>
                <li>
                  <NavLink to="/mcqquestions" className="">
                    MCQ Questions
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/mcqanswers" className="">
                    MCQ Answers
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/matchquestions" className="">
                    Match Questions
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/matchanswers" className="">
                    Match Answers
                  </NavLink>
                </li>
                
              
              </ul>
            </li>
            <li className="sideBarListItem">
              <NavLink to="/aboutus">
                <Campaign className="me-2 activeIcon" />
                About Us
              </NavLink>
            </li>
            <li className="sideBarListItem">
              <NavLink to="/languages">
                <Language className="me-2 activeIcon" />
                Languages
              </NavLink>
            </li>
            <li className="sideBarListItem">
              <NavLink to="/profile">
                <Person className="me-2 activeIcon" />
                My Profile
              </NavLink>
            </li>
            <li className="sideBarListItem">
              <NavLink to="/contactus">
                <SupportAgent className="me-2 activeIcon" />
                Contact Us
              </NavLink>
            </li>
            <li className="sideBarListItem" onClick={logout}>
              <Logout className="me-2" />
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
