import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function viewModal({
  setViewPopUp,
  viewPopUp,
  activity,
  setActivity,
  ar,
  en   
}) {
  if (!activity) return;
  const handleClose = () => {
    setViewPopUp(false);
  };
  return (
    <div>
      <Modal
        open={viewPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={false}
        sx={{ overflowY: "scroll" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            View Row
          </Typography>

          <div className="mb-2 ">
              <label htmlFor="id" className="form-label">
                Id
              </label>
              <input
                className="form-control"
                type="text"
                id="id"
                name="id"
                placeholder={activity.id}
                disabled
              />
            </div>

            <div className="mb-2">
              <label htmlFor="id" className="form-label">
                Name
              </label>
              <input
                className="form-control"
                type="text"
                id="id"
                name="id"
                placeholder={activity.name}
                disabled
              />
            </div>
          <div className="mb-2">
              <label htmlFor="id" className="form-label">
               Points
              </label>
              <input
                className="form-control"
                type="text"
                id="id"
                name="id"
                placeholder={activity.points}
                disabled
              />
            </div>
        
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} className="backBtn me-2">
              Back
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
