import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight:'700px',
  overflowY:'scroll',
  overflowX:'hidden',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function viewModal({
  setViewPopUp,
  viewPopUp,
  setMcqAnswer,
  mcqAnswer,
  ar,
  en,
}) {
  if (!ar || !en) return;
  const handleClose = () => {
    setViewPopUp(false);
    setMcqAnswer("");
  };
  return (
    <div>
      <Modal
        open={viewPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={false}
        sx={{ overflowY: "scroll" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            View Row
          </Typography>

          <div className="d-flex justify-content-center">
            <img
              src={mcqAnswer.image ? mcqAnswer.image : "Not Found"}
              alt="Not Found"
              className="imgStyle"
            />
          </div>
            <div className="mb-2">
              <label htmlFor="id" className="form-label">
                Id
              </label>
              <input
                className="form-control"
                type="text"
                id="id"
                name="id"
                placeholder={mcqAnswer.id}
                disabled
              />
            </div>

            <div className="mb-2">
              <label htmlFor="id" className="form-label">
                Answer
              </label>
              <input
                className="form-control"
                type="text"
                id="id"
                name="id"
                placeholder={mcqAnswer.answer}
                disabled
              />
            </div>
          <div className="mb-2">
            <label htmlFor="id" className="form-label">
              Title
            </label>
            <input
              className="form-control"
              type="text"
              id="id"
              name="id"
              placeholder={mcqAnswer.title}
              disabled
            />
          </div>

          {/* <div className="mb-2 d-flex justify-content-center">
            <label htmlFor="id" className="form-label fw-bold mt-2">
              Arabic
            </label>
          </div>
          <div className="d-flex">
            <div className="mb-2 me-3">
              <label htmlFor="id" className="form-label">
                Language
              </label>
              <div className="mb-2 ">
                <input
                  className="form-control mb-2"
                  type="text"
                  id="name"
                  name="name"
                  placeholder={ar.language ? ar.language : "ar"}
                  disabled
                />
              </div>
            </div>

            <div className="mb-2">
              <label htmlFor="id" className="form-label">
                Title
              </label>
              <div className="mb-2 ">
                <input
                  className="form-control mb-2"
                  type="text"
                  id="name"
                  name="name"
                  placeholder={ar.title ? ar.title : "Not Found"}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="mb-2 d-flex justify-content-center">
            <label htmlFor="id" className="form-label fw-bold mt-2">
              English
            </label>
          </div>

          <div className="d-flex">
            <div className="mb-2 me-3">
              <label htmlFor="id" className="form-label">
                Language
              </label>
              <div className="mb-2 ">
                <input
                  className="form-control mb-2"
                  type="text"
                  id="name"
                  name="name"
                  placeholder={en.language ? en.language : "en"}
                  disabled
                />
              </div>
            </div>
            <div className="mb-2 ">
              <label htmlFor="id" className="form-label">
                Title
              </label>
              <div className="mb-2 ">
                <input
                  className="form-control mb-2"
                  type="text"
                  id="name"
                  name="name"
                  placeholder={en.title ? en.title : "Question Title"}
                  disabled
                />
              </div>
            </div>
          </div> */}
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} className="backBtn me-2">
              Back
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
