import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function viewModal({ quizPopUp, setQuizPopUp, quizs }) {
  const handleClose = () => {
    setQuizPopUp(false);
  };

  return (
    <div>
      <Modal
        open={quizPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={false}
        sx={{ overflowY: "scroll" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            Quizs 
          </Typography>

          <div>
            {quizs
              ? quizs.map((y, index) => {
                  return (
                    <div key={index}>
                    <div className="">
                      <div className="mb-2 ">
                      <label htmlFor="id" className="form-label fw-bold">
                          Quiz {index + 1}
                        </label>
                        
                        <div>
               
                              <div className="d-flex mt-3">
                          <div className="mb-3 ">
                      <label htmlFor="id" className="mb-2">
                            Id
                          </label>
                        <input
                          className="form-control"
                          type="text"
                          id="id"
                          name="id"
                          placeholder={y.id}
                          disabled
                        />
                      </div>
                      <div className="mb-3 ms-3">
                      <label htmlFor="id" className="mb-2">
                            Name
                          </label>
                        <input
                          className="form-control"
                          type="text"
                          id="id"
                          name="id"
                          placeholder={y.name}
                          disabled
                        />
                      </div>
                          </div>
                       </div>
                      </div>
                    </div>
                  </div>
                  );
                })
              : ""}
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} className="backBtn me-2">
              Back
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
