import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Joi from "joi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ getContactUs }) {
  const title = "Contact Us";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Issue";
  const [open, setOpen] = React.useState(false);
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRequestData({});
    setApiErrors(null);
    setErrorList(null);
  };
  const [requestData, setRequestData] = useState({
    name: "",
    message: "",
    subject: "",
    email: "",
    status: 0,
  });
  useEffect(() => {
    setApiErrors([]);
    setErrorList([]);
  }, [requestData]);

  //handle changes in all inputs except image (file)
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };

  //post new country to API
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    requestData.name&&
    formData.append("name", requestData.name);
    requestData.message&&
    formData.append("message", requestData.message);
    requestData.subject&&
    formData.append("subject", requestData.subject);
    formData.append("email", requestData.email);
    requestData.status&&
    formData.append("status", requestData.status);
    let valRes = validateIssue(requestData);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          "https://dashboard.kunozworld.com/api/dashboard/contact_us",
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getContactUs();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
      }
    }
  };

  const validateIssue = (issue) => {
    let schema = Joi.object({
      name: Joi.optional().allow(null),
      message: Joi.optional().allow(null),
      subject: Joi.optional().allow(null),
      email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false}})
      .messages({
        "string.base": `"email" should be valid`,
        "string.empty": `"email" can't be an empty field`,
        "any.required": `"email" is a required field`,
      }),
      status: Joi.optional().allow(null),
    });
    return schema.validate(issue, { abortEarly: false });
  };

  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Issue
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              value={requestData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <input
              className="form-control"
              type="text"
              id="message"
              name="message"
              value={requestData.message}
              onChange={handleChange}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="subject" className="form-label">
              Subject
            </label>
            <input
              className="form-control"
              type="text"
              id="subject"
              name="subject"
              value={requestData.subject}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="email" className="form-label">
              Email
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="email"
              name="email"
              value={requestData.email}
              onChange={handleChange}
            />
          </div>
          <label htmlFor="name" className="form-label">
            Status
          </label>
          <div className="input-group mb-3">
        
            <select
                  className="form-select"
                  id="inputGroupSelect02"
                  name="status"
                  value={requestData.status}
                  onChange={handleChange}
                >
                  <option defaultValue>Choose...</option>
                  <option value="1">1</option>
              <option value="0">0</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
