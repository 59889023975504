import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight:'700px',
  overflowY:'scroll',
  overflowX:'hidden',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getMatchQuestions }) {
  const title = "Match Questions";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add Match Question";
  const [open, setOpen] = useState(false);
  const [questionTag, setQuestionTag] = useState([]);
  const [questionTagShow, setQuestionTagShow] = useState([]);
  const [selectedQuestionTag, setSelectedQuestionTag] = useState([]);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [languageAr, setLanguagesAr] = useState({
    header: "",
    audio: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    header: "",
    audio: null,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setApiErrors(null);
    setErrorList(null);
    setRequestData({});
    setLanguagesAr({});
    setLanguagesEn({});
    setSelectedQuestionTag([]);
    setQuestionTagShow([]);
  };

  const [requestData, setRequestData] = useState({
    degree: null,
  });
 /* question tag */
 const handleQuestionTag = (event) => {
  const {
    target: { value },
  } = event;
  setQuestionTagShow(typeof value === "string" ? value.split(",") : value);
};
const handleChangeSelectQtag = (event) => {
  setSelectedQuestionTag(event.target.value);
};
const getQuestionTag = async () => {
  let { data } = await axios.get(
    "https://dashboard.kunozworld.com/api/dashboard/question-tag",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setQuestionTag(data.data);
};
let questionTagIds = questionTag.map((qTag) => ({
  id:qTag.id,
  title:qTag.title
}));
useEffect(() => {
  getQuestionTag();
}, []);
/*end of question tag*/

  const handleFilesAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      [e.target.files]: e.target.files[0],
    });
  };
  const handleFilesEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      [e.target.files]: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
    
      header: Joi.string().required().messages({
        "string.empty": `"arabic header" can't be an empty field`,
        "any.required": `"arabic header" is a required field`,
      }),
      audio: Joi.optional().allow(null),
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
  
     
      header: Joi.string().required().messages({
        "string.empty": `"english header" can't be an empty field`,
        "any.required": `"english header" is a required field`,
      }),
      audio: Joi.optional().allow(null),
    });

    return schema.validate(en, { abortEarly: false });
  };
  const validateMcq = (requestData) => {
    let schema = Joi.object({
      degree: Joi.number().required().messages({
        "number.empty": `"degree" can't be an empty field`,
        "any.required": `"degree" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateMcq(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);

    const formData = new FormData();
    formData.append("degree", requestData.degree);
    formData.append("languages[0][header]", languageAr.header);

    languageAr.audio &&
      formData.append("languages[0][audio]", languageAr.audio);
    formData.append("languages[0][language]", 'ar');

    formData.append("languages[1][header]", languageEn.header);

    languageEn.audio &&
      formData.append("languages[1][audio]", languageEn.audio);
    formData.append("languages[1][header]", languageEn.header);
    formData.append("languages[1][language]", 'en');
    for (var i = 0; i < selectedQuestionTag.length; i++) {
      formData.append(`question_tag_ids[${i}]`, selectedQuestionTag[i]);
    }
    // console.log(languageAr.audio && 'hello', languageEn, formData);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error && valEn.error) {
      setArErrors(valAr.error.details ? valAr.error.details : "");
      setEnErrors(valEn.error.details ? valEn.error.details : "");
    } else if (valAr.error || valEn.error) {
      if (valAr.error) {
        setArErrors(valAr.error.details ? valAr.error.details : "");
      } else if (valEn.error) {
        setEnErrors(valEn.error.details ? valEn.error.details : "");
      }
    } else {
    try {
      setloading(true);
      let { data } = await axios.post(
        `https://dashboard.kunozworld.com/api/dashboard/match-question`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        }
      );
      if (data.message === "Successful") {
        setOpen(false);
        setloading(false);
        getMatchQuestions();
        setRequestData({});
        setLanguagesAr({});
        setLanguagesEn({});
      }
    } catch (error) {
      setApiErrors(error.response.data.errors);
      setloading(false);
      // console.log(error.response.data.errors);
    }}
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setEnErrors(null);
    setArErrors(null);
  }, [languageEn]);
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add Match Question
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex justify-content-between w-100">
            <div className="w-50 me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="d-flex ">
            <div>
              {/* --------------------------------------------Arabic-------------------------------------------- */}
              <p className="mb-2 text-center  fw-bold">Arabic</p>

              <div className="mb-2">
                <label htmlFor="arHeader" className="form-label">
                  Header
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arHeader"
                  name="header"
                  value={languageAr.header}
                  onChange={handleChangeAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arAudio" className="form-label">
                  Audio
                  <span className="required">  </span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arAudio"
                  name="audio"
                  onChange={handleFilesAr}
                />
              </div>

             

              {/*------------------------------------------End of Arabic---------------------------------------  */}
            </div>
            <div className="ms-3">
              {/*------------------------------------------English---------------------------------------------  */}

              <p className="mb-2 text-center  fw-bold">English</p>

              <div className="mb-2">
                <label htmlFor="enHeader" className="form-label">
                  Header
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enHeader"
                  name="header"
                  value={languageEn.header}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enAudio" className="form-label">
                  Audio
                  <span className="required">  </span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="enAudio"
                  name="audio"
                  onChange={handleFilesEn}
                />
              </div>

            
            </div>
          </div>
          {/*------------------------------------------end of English---------------------------------------------  */}

          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Degree
              <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="degree"
              name="degree"
              value={requestData.degree}
              onChange={handleChange}
            />
          </div>
          <div className="w-100 mb-2">
            <FormControl sx={{ width: "100%" }}>
              <label htmlFor="arLang" className="form-label">
                Question Tags
        
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedQuestionTag}
                onChange={(e) => {
                  handleQuestionTag(e);
                  handleChangeSelectQtag(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {questionTagIds.map((qTag) => (
                  <MenuItem key={qTag.id} value={qTag.id}>
                    <Checkbox
                      checked={questionTagShow.indexOf(qTag.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${qTag.id} [ ${qTag.title} ]` } />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
                 {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
