import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import AddMcqAnswerModal from "../AddMcqAnswer/AddMcqAnswer";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import ViewModal from "../ViewModal/ViewModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import ArabicModal from "../ArabicModal/ArabicModal";
import EnglishModal from "../EnglishModal/EnglishModal";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import McqQuestion from '../McqQuestion/McqQuestion'
export default function McqAnswer() {
  let authToken = localStorage.getItem("userToken");
  const [mcqAnswers, setMcqAnswers] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [mcqAnswerId, setMcqAnswerId] = useState();
  const [mcqAnswer, setMcqAnswer] = useState([]);
  const [ar, setAr] = useState({});
  const [en, setEn] = useState({});
  const [mcqQ, setMcqQ] = useState();
  const [mcqQpopUp, setMcqQPopUp] = useState(false);
  const [arabicPopUp, setArabicPopUp] = useState(false);
  const [englishPopUp, setEnglishPopUp] = useState(false);
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleEn = () => {
    setEnglishPopUp(!englishPopUp);
  };
  const handleAr = () => {
    setArabicPopUp(!arabicPopUp);
  };
  const handleMcqQ = () => {
    setMcqQPopUp(!mcqQpopUp);
  };
  const getMcqAnswers = async () => {
    let { data } = await axios
      .get("https://dashboard.kunozworld.com/api/dashboard/mcq-answer", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setMcqAnswers(data.data);
  };

  useEffect(() => {
    getMcqAnswers();
  }, []);
  const deleteRow = (id) => {
    const temp = [...mcqAnswers];
    axios.delete(
      `https://dashboard.kunozworld.com/api/dashboard/mcq-answer/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMcqAnswers(temp);
    getMcqAnswers();
    setDeletePopUp(!deletePopUp);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/mcq-answer/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMcqAnswer(data.data[0]);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/mcq-answer/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMcqAnswer(data.data[0]);
    setAr(data.data[0].languages[0]);
    setEn(data.data[0].languages[1]);
  };
  const viewLang = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/mcq-answer/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMcqQ(data.data[0].mcq_question);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  return (
    <div className="">
      <AddMcqAnswerModal getMcqAnswers={getMcqAnswers} />
      <ArabicModal
        ar={ar}
        arabicPopUp={arabicPopUp}
        setArabicPopUp={setArabicPopUp}
      />
      <EnglishModal
        en={en}
        englishPopUp={englishPopUp}
        setEnglishPopUp={setEnglishPopUp}
      />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getMcqAnswers={getMcqAnswers}
        deleteRow={deleteRow}
        id={mcqAnswerId}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        mcqAnswer={mcqAnswer}
        setMcqAnswer={setMcqAnswer}
        ar={ar}
        en={en}
      />
      <UpdatePopUp
        ar={ar}
        en={en}
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        mcqAnswer={mcqAnswer}
        setMcqAnswer={setMcqAnswer}
        id={mcqAnswerId}
        getMcqAnswers={getMcqAnswers}
      />
       <McqQuestion
        setMcqQPopUp={setMcqQPopUp}
        mcqQ={mcqQ}
        mcqQpopUp={mcqQpopUp}
      />
      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
           <div className="deleteForeverDiv mb-3">
        
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
           <div
          className="describeDel"
          onClick={() => {
            handleDeletePermenant();
          }}
        >
          Trash
        </div>
        </div>
       
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Answer
                </TableCell>

            
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                Mcq Q
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Languages
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mcqAnswers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
          
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">
                        <img src={row.image} className="img" alt="not found" />
                      </TableCell>
                      <TableCell align="center">{row.answer}</TableCell>


                           <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleMcqQ();
                              viewLang(row.id);
                            }}
                          >
                           Mcq Q
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign me-1"
                            onClick={() => {
                              handleEn();
                              viewLang(row.id);
                            }}
                          >
                            EN
                          </button>
                          <div className="divider align-self-center"></div>
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleAr();
                              viewLang(row.id);
                            }}
                          >
                            AR
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setMcqAnswerId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setMcqAnswerId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={mcqAnswers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
