// #1976D2 blue
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const normalTheme = createTheme({
  palette: {
    primary: {
      main: "rgba(125, 82, 179, 1)",
      contrastText: "#ffffff",
    },
  },
});

const Theme = (props) => {
  const { /*theme,*/ children } = props;

  return <ThemeProvider theme={normalTheme}>{children}</ThemeProvider>;
};

export default Theme;
