import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getMatchAnswers }) {
  const title = "Match Answers";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add Match Answer";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [matchQs,setMatchQs]=useState([]);
  const [matchAs,setMatchAs]=useState([]);
  const [languageAr, setLanguagesAr] = useState({
    title: null,
    audio: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    title: null,
    audio: null,
  });
  const [requestData, setRequestData] = useState({
    image: null,
    match_question_id: null,
    match_answer_id: null,
    possition: "",
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRequestData({});
    setLanguagesAr({});
    setLanguagesEn({});
  };
  const handleImage = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
  };
  const handleFilesAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      [e.target.files]: e.target.files[0],
    });
  };
  const handleFilesEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      [e.target.files]: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };

  const validateArabic = (ar) => {
    let schema = Joi.object({

      title: Joi.optional().allow(null),

      audio: Joi.optional().allow(null),
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({

      title: Joi.optional().allow(null),
      audio: Joi.optional().allow(null),
    });

    return schema.validate(en, { abortEarly: false });
  };
  const validateMcq = (requestData) => {
    let schema = Joi.object({
      image: Joi.optional().allow(null),
      match_answer_id: Joi.number().allow(null),
      match_question_id: Joi.number().required().messages({
        "string.empty": `"match_question_id" can't be an empty field`,
        "any.required": `"match_question_id" is a required field`,
      }),
      possition: Joi.string().required().messages({
        "string.empty": `"position" can't be an empty field`,
        "any.required": `"position" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    let valRes = validateMcq(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    formData.append("match_question_id", requestData.match_question_id);
    requestData.image && formData.append("image", requestData.image);
    languageEn.title &&
      formData.append("languages[0][title]", languageAr.title);
    languageAr.audio &&
      formData.append("languages[0][audio]", languageAr.audio);
    formData.append("languages[0][language]", 'ar');
    languageEn.title &&
      formData.append("languages[1][title]", languageEn.title);
    languageEn.audio &&
      formData.append("languages[1][audio]", languageEn.audio);
    formData.append("languages[1][language]", 'en');
    formData.append("possition", requestData.possition);
    requestData.match_answer_id &&
      formData.append("match_answer_id", requestData.match_answer_id);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);

      setEnErrors(valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/match-answer`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getMatchAnswers();
          setRequestData("");
        }
      } catch (error) {
        getMatchAnswers();
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageEn]);
   //get match q Id
 const getMatchQids = async () => {
  let { data } = await axios.get(
    "https://dashboard.kunozworld.com/api/dashboard/match-question",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setMatchQs(data.data);
};
let matchQids = matchQs.map((matchQ) => ({
  id: matchQ.id,
  header: matchQ.header,
}));
useEffect(() => {
  getMatchQids();
}, []);
//end of match q id
   //get match a Id
   const getMatchAids = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/match-answer",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMatchAs(data.data);
  };
  let matchAids = matchAs.map((matchA) => ({
    id: matchA.id,
    title: matchA.title,
  }));
  useEffect(() => {
    getMatchAids();
  }, []);
  //end of match a id
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add Match Answer
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex justify-content-between w-100">
            <div className="w-50 me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} : {apiErrors[key][0]}
              </div>
            ))}

          <div className="d-flex ">
            <div>
              {/* --------------------------------------------Arabic-------------------------------------------- */}
              <p className="mb-2 text-center  fw-bold">Arabic</p>

              <div className="mb-2">
                <label htmlFor="arHeader" className="form-label">
                  Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="title"
                  value={languageAr.title}
                  onChange={handleChangeAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arAudio" className="form-label">
                  Audio
                  <span className="required"> </span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arAudio"
                  name="audio"
                  onChange={handleFilesAr}
                />
              </div>

              

              {/*------------------------------------------End of Arabic---------------------------------------  */}
            </div>
            <div className="ms-3">
              {/*------------------------------------------English---------------------------------------------  */}

              <p className="mb-2 text-center  fw-bold">English</p>

              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Title
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="title"
                  value={languageEn.title}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enAudio" className="form-label">
                  Audio
                  <span className="required"> </span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="enAudio"
                  name="audio"
                  onChange={handleFilesEn}
                />
              </div>

            </div>
          </div>
          {/*------------------------------------------end of English---------------------------------------------  */}

          <div className="mb-2">
            <label htmlFor="image" className="form-label">
              Image
            </label>
            <input
              className="form-control"
              type="file"
              id="image"
              name="image"
              onChange={handleImage}
            />
          </div>

          <label htmlFor="arLang" className="form-label">
            Positon
            <span className="required"> * </span>
            <span className="hide">This is a Required Feild</span>
          </label>
          <div className="input-group mb-3">
            <select
              className="form-select"
              id="arLang"
              name="possition"
              value={requestData.possition}
              onChange={handleChange}
            >
              <option defaultValue>Choose</option>
              <option value="top">top</option>
              <option value="bottom">bottom</option>
            </select>
            <label className="input-group-text" htmlFor="inputGroupSelect02">
              Options
            </label>
          </div>
          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Match Question 
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.match_question_id}
                  onChange={handleChange}
                  name="match_question_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
             
                  {matchQids.map((machQid) => (
                    <MenuItem key={machQid.id} value={machQid.id}>
                      {machQid.id} {`[ ${machQid.header?machQid.header:"No Title"} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </div>
          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Match Answer 
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.match_answer_id}
                  onChange={handleChange}
                  name="match_answer_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
             
                  {matchAids.map((matchAid) => (
                    <MenuItem key={matchAid.id} value={matchAid.id}>
                      {matchAid.id} {`[ ${matchAid.title?matchAid.title:"No Title"} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
