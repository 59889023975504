import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import AddQuestionTagModal from "../AddQuestionTagModal/AddQuestionTagModal";
import ViewModal from "../ViewModal/ViewModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import DeleteModal from "../DeletePopUp/DeletePopUpModal";
import LinearProgress from "@mui/material/LinearProgress";
export default function ContactUsTable() {
  let authToken = localStorage.getItem("userToken");
  const [questionTags, setQuestionTags] = useState([]);
  const [questionTag, setQuestionTag] = useState([]);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState();
  const [loading, setloading] = useState(true);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getQuestionTags = async () => {
    let { data } = await axios
      .get("https://dashboard.kunozworld.com/api/dashboard/question-tag", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setQuestionTags(data.data);
  };

  useEffect(() => {
    getQuestionTags();
  }, []);
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/question-tag/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setQuestionTag(data.data[0]);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/question-tag/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setQuestionTag(data.data[0]);
  };
  const deleteRow = (id) => {
    const temp = [...questionTags];
    axios.delete(
      `https://dashboard.kunozworld.com/api/dashboard/question-tag/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setQuestionTags(temp);
    getQuestionTags();
    setDeletePopUp(!deletePopUp);
  };

  return (
    <div className="">
      <AddQuestionTagModal getQuestionTags={getQuestionTags} />
      <DeleteModal
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        deleteRow={deleteRow}
        id={id}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        questionTag={questionTag}
        setQuestionTag={setQuestionTag}
      />
      <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        questionTag={questionTag}
        setQuestionTag={setQuestionTag}
        id={id}
        getQuestionTags={getQuestionTags}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Title
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionTags
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>

                      <TableCell align="center">{row.title}</TableCell>

                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={questionTags.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
