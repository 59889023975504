import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function viewModal({ setViewPopUp, viewPopUp, trash, setTrash }) {
  const handleClose = () => {
    setViewPopUp(false);
    setTrash("");
  };
  return (
    <div>
      <Modal
        open={viewPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            View Row
          </Typography>

          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder={trash.name}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Email{" "}
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder={trash.email}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Phone
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder={trash.phone}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Country Id
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder={trash.country_id}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
             Birthdate
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder={trash.birthdate}
              disabled
            />
          </div>
          <div className="closeBtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClose} className="backBtn me-2">
              Back
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
