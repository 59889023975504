import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Joi from "joi";
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialRequestId = {
  image: null,
    original: null,
    avatar_id: null,
};
export default function UpdatePopUp({
  setUpdatePopUp,
  updatePopUp,
  skin,
  id,
  setSkin,
  getSkins,
  ar,
  en,
}) {
  // if (!skin||!ar||!en) return;

  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [avatars, setAvatars] = useState([]);

  const [requestData, setRequestData] = useState({
...initialRequestId
  });
  const [languageAr, setLanguagesAr] = useState({
    name: "",
  });
  const [languageEn, setLanguagesEn] = useState({
    name: "",
  });
  console.log(skin)
  useEffect(() => {
    if(skin){
      let {original,avatar}=skin;
      setRequestData({original,avatar_id:avatar?.id||0})
    }
  }, [skin]);
  useEffect(() => {
    let{name}=ar;
    setLanguagesAr({name})
  }, [ar]);
  useEffect(() => {
    let{name}=en;
    setLanguagesEn({name})
  }, [en]);
  const handleClose = () => {
    setUpdatePopUp(false);
    setSkin("");
    setRequestData({...initialRequestId})
    setApiErrors(null);
    setErrorList(null);
    setEnErrors(null);
    setArErrors(null);
    setLanguagesAr({});
    setLanguagesEn({});
  };
  const handleImage = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
  };

  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  let authToken = localStorage.getItem("userToken");
  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
 
    });
    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"english name" can't be an empty field`,
        "any.required": `"english name" is a required field`,
      }),
  
    });

    return schema.validate(en, { abortEarly: false });
  };
  const validateSkin = (requestData) => {
    let schema = Joi.object({
  
      original: Joi.number().required().messages({
        "boolean.empty": `"original" can't be an empty field`,
        "any.required": `"original" is a required field`,
      }),
      avatar_id: Joi.number().required().messages({
        "number.empty": `"avatar_id" can't be an empty field`,
        "any.required": `"avatar_id" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateSkin(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();
    requestData.image&&
    formData.append("image", requestData.image);
    languageAr.name && formData.append("languages[0][name]",languageAr.name);
    formData.append("languages[0][language]", 'ar');
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][language]", 'en');
    formData.append("original", requestData.original);
    formData.append("avatar_id", requestData.avatar_id);
    formData.append("name", requestData.name);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);

      setEnErrors(valEn.error.details);
    } else {
      try {
        setLoading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/skin/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setLoading(false);
          getSkins();
          setRequestData("");
        }
      } catch (error) {
        getSkins();
        setApiErrors(error.response.data.errors);
        setLoading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageEn]);
  //get avatar type Id
  const getAvatarsIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/avatar",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAvatars(data.data);
  };
  let avatarsIds = avatars.map((avatar) => ({
    id: avatar.id,
    name: avatar.name,
  }));
  useEffect(() => {
    getAvatarsIds();
  }, []);
  //end of quiz type
  if (!skin || !ar || !en) return;

  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="mb-2">
            <label htmlFor="arAudio" className="form-label">
              Image
            
            </label>
            <input
              className="form-control"
              type="file"
              id="arAudio"
              name="image"
              onChange={handleImage}
            />
          </div>

          <label htmlFor="arLang" className="form-label">
            Original
            <span className="required"> * </span>
            <span className="hide">This is a Required Feild</span>
          </label>
          <div className="input-group mb-3">
              <select
                className="form-select"
                id="arLang"
                name="original"
                value={requestData.original}
                onChange={handleChange}
              >
                <option defaultValue>Choose...</option>
                <option value="0">0</option>
                <option value="1">1</option>
              </select>
              <label className="input-group-text" htmlFor="inputGroupSelect02">
                Options
              </label>
            </div>
          <div className="mb-3">
            <label htmlFor="arVideo" className="form-label">
              Avatar 
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={requestData.avatar_id}
                onChange={handleChange}
                name="avatar_id"
                MenuProps={MenuProps}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                className="customSelect"
              >
                {avatarsIds.map((avatar) => (
                  <MenuItem key={avatar.id} value={avatar.id}>
                    {avatar.id} {`[ ${avatar.name ? avatar.name : "No Title"} ]`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex w-100">
            {/* -----------------------------------------arabic-------------------------------------------- */}

            <div className="w-50">
              <p className="mb-2  mt-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  placeholder={ar.name}
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>

             
            </div>
            {/* -----------------------------------------english-------------------------------------------- */}

            <div className="w-50 ms-3">
              <p className="mb-2 mt-2 text-center  fw-bold">English</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  placeholder={en.name}
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>

            
            </div>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
