import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function UpdatePopUp({
  setUpdatePopUp,
  updatePopUp,
  trueAndFalseQ,
  id,
  setTrueandFalseQ,
  getTrueAndFalse,
  ar,
  en,
}) {
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [questionTag, setQuestionTag] = useState([]);
  const [questionTagShow, setQuestionTagShow] = useState([]);
  const [selectedQuestionTag, setSelectedQuestionTag] = useState([]);
  const [languageAr, setLanguagesAr] = useState({
    title: "",
    header: "",
    video: null,
    audio: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    title: "",
    header: "",
    video: null,
    audio: null,
  });
  const [requestData, setRequestData] = useState({
    image: null,
    answer: 0,
    degree: 1,
    level: "easy",
  });
  useEffect(() => {
    let { answer, degree, level } = trueAndFalseQ;
    setRequestData({ answer, degree, level });
  }, [trueAndFalseQ]);
  useEffect(() => {
    let { title, header } = ar;
    setLanguagesAr({ title, header });
  }, [ar]);
  useEffect(() => {
    let { title, header } = en;
    setLanguagesEn({ title, header });
  }, [en]);
  /* question tag */
  const handleQuestionTag = (event) => {
    const {
      target: { value },
    } = event;
    setQuestionTagShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectQtag = (event) => {
    setSelectedQuestionTag(event.target.value);
  };
  const getQuestionTag = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/question-tag",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setQuestionTag(data.data);
  };
  let questionTagIds = questionTag.map((qTag) => ({
    id: qTag.id,
    title: qTag.title,
  }));
  useEffect(() => {
    getQuestionTag();
  }, []);
  /*end of question tag*/
  const handleClose = () => {
    setUpdatePopUp(false);
    setTrueandFalseQ("");
    setLanguagesAr({});
    setLanguagesEn({});
    setSelectedQuestionTag([]);
    setQuestionTagShow([]);
  };
  const handleImage = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
  };
  const handleFilesAr = (e) => {
    // setRequestData({
    //   ...requestData,
    //   languages: [

    //     {
    //       "audio": e.target.files[0],
    //       "video": e.target.files[0],
    //     },
    //   ],
    // });
    setLanguagesAr({
      ...languageAr,
      audio: e.target.files[0],
      video: e.target.files[0],
    });
  };
  const handleFilesEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      // "audio": e.target.files[0],
      // "video": e.target.files[0],
      audio: e.target.files[0],
      video: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  let authToken = localStorage.getItem("userToken");

  const validateArabic = (ar) => {
    let schema = Joi.object({
      title: Joi.string().required().messages({
        "string.empty": `"arabic title" can't be an empty field`,
        "any.required": `"arabic title" is a required field`,
      }),

      header: Joi.string().required().messages({
        "string.empty": `"arabic header" can't be an empty field`,
        "any.required": `"arabic header" is a required field`,
      }),
      video: Joi.optional().allow(null),
      audio: Joi.optional().allow(null),
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      title: Joi.string().required().messages({
        "string.empty": `"english title" can't be an empty field`,
        "any.required": `"english title" is a required field`,
      }),

      header: Joi.string().required().messages({
        "string.empty": `"english header" can't be an empty field`,
        "any.required": `"english header" is a required field`,
      }),
      video: Joi.optional().allow(null),
      audio: Joi.optional().allow(null),
    });

    return schema.validate(en, { abortEarly: false });
  };
  const validateTrueAndFalse = (requestData) => {
    let schema = Joi.object({
      answer: Joi.optional().allow(null),
      degree: Joi.optional().allow(null),
      level: Joi.optional().allow(null),
      image: Joi.optional().allow(null),
      languages: Joi.array(),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    let valRes = validateTrueAndFalse(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    requestData.image && formData.append("image", requestData.image);
    requestData.degree && formData.append("degree", requestData.degree);
    requestData.level && formData.append("level", requestData.level);
    requestData.answer && formData.append("answer", requestData.answer);
    formData.append("languages[0][title]", languageAr.title);
    languageAr.audio &&
      formData.append("languages[0][audio]", languageAr.audio);
    formData.append("languages[0][header]", languageAr.header);
    formData.append("languages[0][language]", languageAr.language);
    languageAr.video &&
      formData.append("languages[0][video]", languageAr.video);

    formData.append("languages[1][title]", languageEn.title);
    languageEn.audio &&
      formData.append("languages[1][audio]", languageEn.audio);
    formData.append("languages[1][header]", languageEn.header);
    formData.append("languages[1][language]", languageEn.language);
    languageEn.video &&
      formData.append("languages[1][video]", languageEn.video);
    for (var i = 0; i < selectedQuestionTag.length; i++) {
      formData.append(`question_tag_ids[${i}]`, selectedQuestionTag[i]);
    }
    if (valRes.error) {
      setErrorList(valRes.error && valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error && valAr.error.details);

      setEnErrors(valEn.error && valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/true-false-question/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getTrueAndFalse();
          setRequestData("");
        }
      } catch (error) {
        getTrueAndFalse();
        setApiErrors(error.response.data.errors);
        setloading(false);

        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageEn]);
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex justify-content-between w-100">
            <div className="w-50 me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="d-flex ">
            <div>
              {/* --------------------------------------------Arabic-------------------------------------------- */}
              <p className="mb-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Title
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="title"
                  placeholder={ar && ar.title}
                  value={languageAr.title}
                  onChange={handleChangeAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arHeader" className="form-label">
                  Header
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arHeader"
                  name="header"
                  placeholder={ar && ar.header}
                  value={languageAr.header}
                  onChange={handleChangeAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arAudio" className="form-label">
                  Audio
                  <span className="required"> </span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arAudio"
                  name="audio"
                  onChange={handleFilesAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arVideo" className="form-label">
                  Video
                  <span className="required"> </span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arVideo"
                  name="video"
                  onChange={handleFilesAr}
                />
              </div>

              {/*------------------------------------------End of Arabic---------------------------------------  */}
            </div>
            <div className="ms-3">
              {/*------------------------------------------English---------------------------------------------  */}

              <p className="mb-2 text-center  fw-bold">English</p>

              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Title
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="title"
                  placeholder={en && en.title}
                  value={languageEn.title}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enHeader" className="form-label">
                  Header
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enHeader"
                  name="header"
                  placeholder={en && en.header}
                  value={languageEn.header}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enAudio" className="form-label">
                  Audio
                  <span className="required"> </span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="enAudio"
                  name="audio"
                  onChange={handleFilesEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enVideo" className="form-label">
                  Video
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="enVideo"
                  name="video"
                  onChange={handleFilesEn}
                />
              </div>
            </div>
          </div>
          {/*------------------------------------------end of English---------------------------------------------  */}

          <div className="mb-2">
            <label htmlFor="image" className="form-label">
              Image
            </label>
            <input
              className="form-control"
              type="file"
              id="image"
              name="image"
              onChange={handleImage}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="degree" className="form-label">
              Degree
            </label>
            <input
              className="form-control"
              type="text"
              id="degree"
              name="degree"
              placeholder={trueAndFalseQ.degree}
              value={requestData.degree}
              onChange={handleChange}
            />
          </div>
          <div className="w-100 mb-2">
            <FormControl sx={{ width: "100%" }}>
              <label htmlFor="arLang" className="form-label">
                Question Tags
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedQuestionTag}
                onChange={(e) => {
                  handleQuestionTag(e);
                  handleChangeSelectQtag(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {questionTagIds.map((qTag) => (
                  <MenuItem key={qTag.id} value={qTag.id}>
                    <Checkbox
                      checked={questionTagShow.indexOf(qTag.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${qTag.id} [ ${qTag.title} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex w-100">
            <div className="w-50">
              <label htmlFor="arLang" className="form-label">
                Level
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="level"
                  placeholder={trueAndFalseQ.level}
                  value={requestData.level}
                  onChange={handleChange}
                >
                  <option defaultValue>{trueAndFalseQ.level}</option>
                  <option value="easy">Easy</option>
                  <option value="medium">Medium</option>
                  <option value="hard">Hard</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div>
            </div>
            <div className="ms-2 w-50">
              <label htmlFor="arLang" className="form-label">
                Answer
              </label>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="arLang"
                  name="answer"
                  placeholder={trueAndFalseQ.answer}
                  value={requestData.answer}
                  onChange={handleChange}
                >
                  <option defaultValue>{trueAndFalseQ.answer}</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Options
                </label>
              </div>
            </div>
          </div>

          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
