import React, { useState, useEffect } from "react";
import avatar from "../../images/img.png";
import "./TopBar.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const TopBar = () => {
  let navigate=useNavigate();
  let loggedId = localStorage.getItem("loggedId");
  let authToken = localStorage.getItem("userToken");
  const [user, setUser] = useState({});
  const viewProfile = async (loggedId) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/user/${loggedId}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setUser(data.data.UserModel);
  };
  useEffect(() => {
    viewProfile(loggedId);
  }, []);
  const visitProfile =()=>{
    navigate('/profile')
  }
  return (
    <div className="topBar mt-3">
      <div className="d-flex">
        {user.avatar ? (
          <div>
            <img src={user.avatar} alt="" className="topAvatar"  onClick={visitProfile}/>
          </div>
        ) : (
          <div>
            <img src={avatar} alt="" className="topAvatar" onClick={visitProfile}/>
          </div>
        )}
        {/* <p className="topText ms-2 mt-4">{user.name}</p> */}
        <div className="align-self-center ms-2 topText">
          {user.name}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
