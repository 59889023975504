import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ getPlayTimes }) {
  const title = "Play Times";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add Play Time";
  const [open, setOpen] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [time, setTime] = React.useState(new Date());
  const [isLoading, setloading] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [subUsers, setSubUsers] = useState([]);
  const [allDates, setAllDates] = useState([
    { num: 0, start: "09:00:00", end: "21:00:00", status: 0 },
    { num: 1, start: "09:00:00", end: "21:00:00", status: 0 },
    { num: 2, start: "09:00:00", end: "21:00:00", status: 0 },
    { num: 3, start: "09:00:00", end: "21:00:00", status: 0 },
    { num: 4, start: "09:00:00", end: "21:00:00", status: 0 },
    { num: 5, start: "09:00:00", end: "21:00:00", status: 0 },
    { num: 6, start: "09:00:00", end: "21:00:00", status: 0 },
  ]);

  const [requestData, setRequestData] = useState({
    sub_user_id: null,
  });

  useEffect(() => {
    console.log(allDates);
  }, [allDates]);

  const validate = (requestData) => {
    let schema = Joi.object({
      sub_user_id: Joi.number().required().messages({
        "string.empty": `"sub_user_id" can't be an empty field`,
        "any.required": `"sub_user_id" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const stringDateParse = (str) => {
    let newStr = `2022-01-10T${str}`;
    return new Date(newStr);
  };

  const DateStringParse = (date = new Date()) => {
    let houers = date.getHours() < 9 ? "0" + date.getHours() : date.getHours();
    let minutes =
      date.getMinutes() < 9 ? "0" + date.getMinutes() : date.getMinutes();
    return `${houers}:${minutes}:00`;
  };

  const handleTimesChange = (num, type, newValue) => {
    let newArray = [...allDates];
    newArray[num][type] = DateStringParse(newValue);
    setAllDates([...newArray]);
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleStatusChange = (num, newValue) => {
    let newArray = [...allDates];
    newArray[num].status = newValue;
    setAllDates([...newArray]);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRequestData({});

  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validate(requestData);
    const formData = new FormData();
    formData.append("sub_user_id", requestData.sub_user_id);
    allDates.forEach((item) => {
      formData.append(`day[${item.num}]`, item.num);
      formData.append(`status[${item.num}]`, item.status);
      formData.append(`start[${item.num}]`, item.start);
      formData.append(`end[${item.num}]`, item.end);
    });
    for (const value of formData.values()) {
      console.log(value);
    }
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/play-time/attatch-array`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getPlayTimes();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  //get subuser Id
  const getSubUsersIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/sub-user",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubUsers(data.data);
  };
  let subUsersIds = subUsers.map((subUser) => ({
    id: subUser.id,
    name: subUser.name,
  }));
  useEffect(() => {
    getSubUsersIds();
  }, []);
  //end of subsubject
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Play Time
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} : {apiErrors[key][0]}
              </div>
            ))}
          <div className="w-100 mb-2">
            <label htmlFor="arLang" className="form-label">
              Sub-users Id
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={requestData.sub_user_id}
                    onChange={handleChange}
                    name="sub_user_id"
                    MenuProps={MenuProps}
                    InputLabelProps={{ shrink: false }}
                    input={<OutlinedInput label=" " />}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    className="customSelect"
                  >
                    {subUsersIds.map((subUser) => (
                      <MenuItem key={subUser.id} value={subUser.id}>
                        {subUser.id}{" "}
                        {`[${subUser.name ? subUser.name : "No Title"}]`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
          </div>

          <div className="d-flex">
            <div className="">
              {allDates.map((specificDay, key) => {
                return (
                  <div key={key} style={{ width: "100%" }}>
                    {key == 0 ? (
                      <label className="me-2 fw-bolder mb-3">
                        Sunday <span className="required"> * </span>
                      </label>
                    ) : key == 1 ? (
                      <label className="me-2 fw-bolder mb-3">
                        Monday <span className="required"> * </span>
                      </label>
                    ) : key == 2 ? (
                      <label className="me-2 fw-bolder mb-3">
                        Tuesday <span className="required"> * </span>
                      </label>
                    ) : key == 3 ? (
                      <label className="me-2 fw-bolder mb-3">
                        Wedenday <span className="required"> * </span>
                      </label>
                    ) : key == 4 ? (
                      <label className="me-2 fw-bolder mb-3">
                        Thursday <span className="required"> * </span>
                      </label>
                    ) : key == 5 ? (
                      <label className="me-2 fw-bolder mb-3">
                        Friday <span className="required"> * </span>
                      </label>
                    ) : key == 6 ? (
                      <label className="me-2 fw-bolder mb-3">
                        Saturday <span className="required"> * </span>
                      </label>
                    ) : (
                      ""
                    )}
                    <div className="d-flex">
                      <div style={{ width: "40%" }} className={"mb-3 me-2"}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            openTo="hours"
                            ampm={false}
                            views={["hours", "minutes", "seconds"]}
                            inputFormat="HH:mm:ss"
                            mask="__:__:__"
                            label="Start"
                            value={stringDateParse(specificDay.start)}
                            onChange={(newTime) => {
                              handleTimesChange(key, "start", newTime);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      <div style={{ width: "40%" }} className="mb-3 me-2">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            ampm={false}
                            openTo="hours"
                            views={["hours", "minutes", "seconds"]}
                            inputFormat="HH:mm:ss"
                            mask="__:__:__"
                            label="End"
                            value={stringDateParse(specificDay.end)}
                            onChange={(newTime) => {
                              handleTimesChange(key, "end", newTime);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                      <div>
                        <FormControl
                          style={{ width: "100%" }}
                          className="mb-3 "
                        >
                          <InputLabel id={`demo-simple-select-label${key}`}>
                            Status
                          </InputLabel>
                          <Select
                            labelId={`demo-simple-select-label${key}`}
                            id="demo-simple-select"
                            value={specificDay.status}
                            label="Status"
                            onChange={(event) => {
                              handleStatusChange(key, event.target.value);
                            }}
                          >
                            <MenuItem value={1}>Open</MenuItem>
                            <MenuItem value={0}>Close</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButtn">
          
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
