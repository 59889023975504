import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  updatePopUp,
  setUpdatePopUp,
  bodySuit,
  id,
  getBodySuits,
}) {
  let authToken = localStorage.getItem("userToken");
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [humanPart, setHumanPart] = useState([]);
  const [humanShow, setHumanPshow] = useState([]);
  const [selectedHumanP, setSelectedHumanP] = useState([]);
  const [isLoading, setloading] = useState(false);
  const handleClose = () => {
    setUpdatePopUp(false);
    setRequestData({});
    setApiErrors(null);
    setErrorList(null);
    setHumanPshow([]);
    setSelectedHumanP([]);
    setHumanPart([]);
    
  };

  const [requestData, setRequestData] = useState({
    name: "",
  });
  // console.log(bodySuit)
  useEffect(() => {
    let { name } = bodySuit;
    setRequestData({ name });
  }, [bodySuit]);

  /* humanpart */
  const handleHumanPart = (event) => {
    const {
      target: { value },
    } = event;
    setHumanPshow(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectedHuman = (event) => {
    setSelectedHumanP(event.target.value);
  };
  const getHumanIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/human-part",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setHumanPart(data.data);
  };
  let humanPids = humanPart.map((human) => ({
    id: human.id,
    name: human.name,
  }));  useEffect(() => {
    getHumanIds();
  }, []);
  /*end of humanpart handling*/
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const validation = (requestData) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validation(requestData);
    const formData = new FormData();
    formData.append("name", requestData.name);
    for (var i = 0; i < selectedHumanP.length; i++) {
      formData.append(`human_part_ids[${i}]`, selectedHumanP[i]);
    }
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/body-suit/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getBodySuits();
          setRequestData("");
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  if (!bodySuit) return;
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              value={requestData.name}
              onChange={handleChange}
            />
          </div>
          <div className="w-100">
            <FormControl sx={{ width: "100%" }}>
              <label htmlFor="arLang" className="form-label">
                Human Parts
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedHumanP}
                onChange={(e) => {
                  handleHumanPart(e);
                  handleChangeSelectedHuman(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                   {humanPids.map((humanId) => (
                  <MenuItem key={humanId.id} value={humanId.id}>
                    <Checkbox
                      checked={humanShow.indexOf(humanId.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText
                      primary={`${humanId.id} [ ${humanId.name} ]`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
