import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";

import Checkbox from "@mui/material/Checkbox";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getHeros }) {
  const title = "Heros";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Hero";
  const [open, setOpen] = useState(false);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [lesson, setLesson] = useState([]);
  const [lessonShow, setLessonShow] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState([]);
  const [isLoading, setloading] = useState(false);

  /* lesson */
  const handleLesson = (event) => {
    const {
      target: { value },
    } = event;
    setLessonShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectLesson = (event) => {
    setSelectedLesson(event.target.value);
  };
  const getLessonIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/lesson",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setLesson(data.data);
  };
  let lessonIds = lesson.map((lessonId) =>({
    id:lessonId.id,
    name:lessonId.name
  }));
  useEffect(() => {
    getLessonIds();
  }, []);
  /*end of lesson handling*/
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setApiErrors(null);
    setEnErrors(null);
    setArErrors(null);
    setloading(false);
  };
  const [languageAr, setLanguagesAr] = useState({
    title: "",
    description: "",
    image: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    title: "",
    description: "",
    image: null,
  });
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageAr = (e) => {
    setLanguagesAr({ ...languageAr, image: e.target.files[0] });
  };
  const handleImageEn = (e) => {
    setLanguagesEn({ ...languageEn, image: e.target.files[0] });
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
      title: Joi.string().required().messages({
        "string.empty": `"arabic title" can't be an empty field`,
        "any.required": `"arabic title" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"arabic description" can't be an empty field`,
        "any.required": `"arabic description" is a required field`,
      }),
      image: Joi.object().required().messages({
        "object.base": `"arabic image" can't be an empty field`,
      }),
   
    });
    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      title: Joi.string().required().messages({
        "string.empty": `"english title" can't be an empty field`,
        "any.required": `"english title" is a required field`,
      }),
      description: Joi.string().required().messages({
        "string.empty": `"english description" can't be an empty field`,
        "any.required": `"english description" is a required field`,
      }),
      image: Joi.object().required().messages({
        "object.base": `"english image" can't be an empty field`,
      }),
   
    });

    return schema.validate(en, { abortEarly: false });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();

    formData.append("languages[0][title]", languageAr.title);
    formData.append("languages[0][description]", languageAr.description);
    formData.append("languages[0][image]", languageAr.image);
    formData.append("languages[0][language]", 'ar');
    formData.append("languages[1][title]", languageEn.title);
    formData.append("languages[1][description]", languageEn.description);
    formData.append("languages[1][image]", languageEn.image);
    formData.append("languages[1][language]", 'en');
    for (var i = 0; i < selectedLesson.length; i++) {
      formData.append(`lesson_ids[${i}]`, selectedLesson[i]);
    }
    if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);
      setEnErrors(valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/hero`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getHeros();
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };

  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setEnErrors(null);
    setArErrors(null);
  }, [languageEn]);
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Hero
          </Typography>

          <div className="d-flex">
            <div className="me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div>
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

        
          <div className="d-flex ">
            {/* -----------------------------------------arabic-------------------------------------------- */}

            <div className="w-50 me-3 ">
              <p className="mb-2  mt-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Title
                  <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="title"
                  value={languageAr.title}
                  onChange={handleChangeAr}
                />
              </div>

              
              <div className="mb-2">
            <label htmlFor="arAudio" className="form-label">
              Image
              <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="arAudio"
              name="image"
              onChange={handleImageAr}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="arVideo" className="form-label">
            Description
            <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <textarea
              className="form-control"
              type="text"
              id="arVideo"
              name="description"
              value={languageAr.description}
              onChange={handleChangeAr}
            />
          </div>
            </div>

            {/* -----------------------------------------english-------------------------------------------- */}
            <div className="w-50">
              <p className="mb-2  mt-2 text-center  fw-bold">English</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Title
                  <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="title"
                  value={languageEn.title}
                  onChange={handleChangeEn}
                />
              </div>

            
              <div className="mb-2">
            <label htmlFor="arAudio" className="form-label">
              Image
              <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="arAudio"
              name="image"
              onChange={handleImageEn}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="arVideo" className="form-label">
            Description
            <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <textarea
              className="form-control"
              type="text"
              id="arVideo"
              name="description"
              value={languageEn.description}
              onChange={handleChangeEn}
            />
          </div>
            </div>
          </div>
          
         <div className="w-100">
         <FormControl sx={{ width: '100%' }}>
              <label htmlFor="arLang" className="form-label">
                Lessons
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedLesson}
                onChange={(e) => {
                  handleLesson(e);
                  handleChangeSelectLesson(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {lessonIds.map((lesson) => (
                  <MenuItem key={lesson.id} value={lesson.id}>
                    <Checkbox
                      checked={lessonShow.indexOf(lesson.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${lesson.id} [ ${lesson.name} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

         </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
