import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import AddUserModal from "../AddUserModal/AddUserModal";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import ViewModal from "../ViewModal/ViewModal";
import UpdateModal from "../UpdateModal/UpdateModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
export default function Mcq() {
  let authToken = localStorage.getItem("userToken");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [userRole,setUserRole]=useState([]);
  const [id, setId] = useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };

  const getUsers = async () => {
    let { data } = await axios
      .get("https://dashboard.kunozworld.com/api/dashboard/user/", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setUsers(data.data);
  };

  useEffect(() => {
    getUsers();
  }, []);
  const deleteRow = (id) => {
    const temp = [...users];
    axios.delete(`https://dashboard.kunozworld.com/api/dashboard/user/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    });
    setUsers(temp);
    getUsers();
    setDeletePopUp(!deletePopUp);
  };

  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/user/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setUser(data.data.UserModel);
    setUserRole(data.data.UserModel.UserRoles[0]);
  };
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/user/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setUser(data.data.UserModel);
  };
  return (
    <div className="">
      <AddUserModal getUsers={getUsers} />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getUsers={getUsers}
        deleteRow={deleteRow}
        id={id}
      />
      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        user={user}
        setUser={setUser}
        userRole={userRole}
      />
      <UpdateModal
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        user={user}
        setUser={setUser}
        id={id}
        getUsers={getUsers}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
   <div className="deleteForeverDiv mb-3">
        
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
           <div
          className="describeDel"
          onClick={() => {
            handleDeletePermenant();
          }}
        >
          Trash
        </div>
        </div>
       
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Email
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                 Role
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Phone
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  BirthDate
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">{row.name}</TableCell>

                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.UserRoles&&row.UserRoles[0].name}</TableCell>

                      <TableCell align="center">{row.phone}</TableCell>

                      <TableCell align="center">{row.birthdate}</TableCell>

                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
