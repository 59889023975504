import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight:'700px',
  overflowY:'scroll',
  overflowX:'hidden',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ getQuizs }) {
  const title = "Quizs ";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Quiz";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [mcq, setMcq] = useState([]);
  const [match, setMatch] = useState([]);
  const [tandf, setTandF] = useState([]);
  const [mcqShow, setmcqShow] = useState([]);
  const [matchShow, setmatchShow] = useState([]);
  const [tAndfShow, setTandFshow] = useState([]);
  const [selectedMcq, setselectedMcq] = useState([]);
  const [selectedMatch, setselectedMatch] = useState([]);
  const [selectTaF, setselectedTaF] = useState([]);
  const [isLoading, setloading] = useState(false);
  const [quizTypes,setQuizTypes]=useState([]);
  const [requestData, setRequestData] = useState({
    points: 0,
    minimum_requirements: 0,
    quiz_type_id: null,
  });
  const [languageAr, setLanguagesAr] = useState({
    name: "",
    image_one: null,
    image_two: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    name: "",
    image_one: null,
    image_two: null,
  });
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageOneAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      image_one: e.target.files[0],
    });
  };
  const handleImageTwoAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      image_two: e.target.files[0],
    });
  };
  const handleImageOneEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      image_one: e.target.files[0],
    });
  };
  const handleImageTwoEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      image_two: e.target.files[0],
    });
  };
  const handleMcq = (event) => {
    const {
      target: { value },
    } = event;
    setmcqShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleMatch = (event) => {
    const {
      target: { value },
    } = event;
    setmatchShow(typeof value === "string" ? value.split(",") : value);
  };
  const handleTandF = (event) => {
    const {
      target: { value },
    } = event;
    setTandFshow(typeof value === "string" ? value.split(",") : value);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRequestData({});
    setLanguagesAr({});
    setLanguagesEn({});
    setselectedMatch([]);
    setselectedMcq([]);
    setselectedTaF([]);
  };
  const handleChangeSelectMcq = (event) => {
    setselectedMcq(event.target.value);
  };
  const handleChangeSelectedMatch = (event) => {
    setselectedMatch(event.target.value);
  };
  const handleChangeSelectedTaF = (event) => {
    setselectedTaF(event.target.value);
  };
  const getMcqQid = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/mcq-question",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMcq(data.data);
  };
  let mcqIds = mcq.map((mcqId) =>({
    id:mcqId.id,
    title:mcqId.title
  }));
  // let mcqTitles = mcq.map((title) => title.title);
  useEffect(() => {
    getMcqQid();
  }, []);
  const getMatchQid = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/match-question",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setMatch(data.data);
  };
  let matchIds = match.map((matchId) => ({
    id:matchId.id,
    header:matchId.header
  }));
  useEffect(() => {
    getMatchQid();
  }, []);
  const getTandFid = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/true-false-question",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setTandF(data.data);
  };
  let tAndFids = tandf.map((tf) =>({
    id:tf.id,
    title:tf.title
  }));
  useEffect(() => {
    getTandFid();
  }, []);

  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
     
      image_one: Joi.object().required().messages({
        "object.base": `"arabic image1" can't be an empty field`,
      }),
      image_two: Joi.object().required().messages({
        "object.base": `"arabic image2" can't be an empty field`,
      }),
    });
    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
      
      image_one: Joi.object().required().messages({
        "object.base": `"english image1" can't be an empty field`,
      }),
      image_two: Joi.object().required().messages({
        "object.base": `"english image1" can't be an empty field`,
      }),
    });

    return schema.validate(en, { abortEarly: false });
  };

  const validateQuiz = (requestData) => {
    let schema = Joi.object({
    
      points: Joi.optional().allow(null),
      minimum_requirements:Joi.optional().allow(null),
      quiz_type_id: Joi.number().required().messages({
        "number.empty": `"quiz_type_id" can't be an empty field`,
        "any.required": `"quiz_type_id" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setEnErrors(null);
    setArErrors(null);
  }, [languageEn]);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateQuiz(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);
    const formData = new FormData();
    requestData.points&&
    formData.append("points", requestData.points);
    requestData.minimum_requirements&&
    formData.append("minimum_requirements", requestData.minimum_requirements);
    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][language]", 'ar');
    formData.append("languages[0][image_one]", languageAr.image_one);
    formData.append("languages[0][image_two]", languageAr.image_two);
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][language]", 'en');
    formData.append("languages[1][image_one]", languageEn.image_one);
    formData.append("languages[1][image_two]", languageEn.image_two);
    for (var i = 0; i < selectedMcq.length; i++) {
      formData.append(`mcq_question_ids[${i}]`, selectedMcq[i]);
    }
    for (var i = 0; i < selectTaF.length; i++) {
      formData.append(`true_false_question_ids[${i}]`, selectTaF[i]);
    }
    for (var i = 0; i < selectedMatch.length; i++) {
      formData.append(`match_question_ids[${i}]`, selectedMatch[i]);
    }
    formData.append("languages[1][image_two]", languageEn.image_two);
    formData.append("quiz_type_id", requestData.quiz_type_id);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);

      setEnErrors(valEn.error.details);
    } else {
    try {
      setloading(true);
      let { data } = await axios.post(
        `https://dashboard.kunozworld.com/api/dashboard/quiz`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        }
      );

      if (data.message === "Successful") {
        setOpen(false);
        setloading(false);
        getQuizs();
        setRequestData({});
      }
    } catch (error) {
      setApiErrors(error.response.data.errors);
      setloading(false);
      console.log(error.response.data.errors);
    }}
  };
    //get quiz type Id
const getQuizTypeIds = async () => {
  let { data } = await axios.get(
    "https://dashboard.kunozworld.com/api/dashboard/quiz-type",
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    }
  );
  setQuizTypes(data.data);
};
let quizTypesIds = quizTypes.map((quizType) => ({
  id: quizType.id,
  name: quizType.name,
}));
useEffect(() => {
  getQuizTypeIds();
}, []);
//end of quiz type
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add Quiz 
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex">
            <div className="me-2 w-50">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger ">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
          <div className="d-flex">
            <div>
              <p className="mb-2  mt-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arAudio" className="form-label">
                  Image One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arAudio"
                  name="image_one"
                  onChange={handleImageOneAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arAudio" className="form-label">
                  Image Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arAudio"
                  name="image_two"
                  onChange={handleImageTwoAr}
                />
              </div>
              
            </div>
            {/* end of arabic */}

            <div className="ms-3">
              <p className="mb-2 mt-2 text-center  fw-bold">English</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arAudio" className="form-label">
                  Image One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arAudio"
                  name="image_one"
                  onChange={handleImageOneEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="arAudio" className="form-label">
                  Image Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arAudio"
                  name="image_Two"
                  onChange={handleImageTwoEn}
                />
              </div>
            
            </div>
          </div>
          <div className="d-flex">
            <FormControl sx={{ width: 312 }}>
              <label htmlFor="arLang" className="form-label">
                Mcq Question 
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedMcq}
                onChange={(e) => {
                  handleMcq(e);
                  handleChangeSelectMcq(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {mcqIds.map((mcq) => (
                  <MenuItem key={mcq.id} value={mcq.id}>
                    <Checkbox
                      checked={mcqShow.indexOf(mcq.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${mcq.id} [ ${mcq.title} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: 312 }} className="ms-3">
              <label htmlFor="arLang" className="form-label">
                Match Question 
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedMatch}
                onChange={(e) => {
                  handleMatch(e);
                  handleChangeSelectedMatch(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {matchIds.map((match) => (
                  <MenuItem key={match.id} value={match.id}>
                    <Checkbox
                      checked={matchShow.indexOf(match.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${match.id} [ ${match.header} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex mt-2 mb-2">
            <FormControl sx={{ width: 312 }}>
              <label htmlFor="arLang" className="form-label">
                True & False Question 
              </label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectTaF}
                onChange={(e) => {
                  handleTandF(e);
                  handleChangeSelectedTaF(e);
                }}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className="customSelect"
              >
                {tAndFids.map((tf) => (
                  <MenuItem key={tf.id} value={tf.id}>
                    <Checkbox
                      checked={tAndfShow.indexOf(tf.id) > -1}
                      style={{
                        color: "rgba(125, 82, 179, 1)",
                      }}
                      iconStyle={{ fill: "white" }}
                    />
                    <ListItemText primary={`${tf.id} [ ${tf.title} ]`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="mb-2 ms-3 w-50">
              <label htmlFor="arTitle" className="form-label">
                Points
              </label>
              <input
                className="form-control"
                type="text"
                id="arTitle"
                name="points"
                value={requestData.points}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="mb-2 me-3 w-50">
              <label htmlFor="arTitle" className="form-label">
                Minimum Requirements
                <span className="required"> </span>
              </label>
              <input
                className="form-control"
                type="text"
                id="arTitle"
                name="minimum_requirements"
                value={requestData.minimum_requirements}
                onChange={handleChange}
              />
            </div>
            <div className="mb-2 w-50">
              <label htmlFor="arTitle" className="form-label">
                Quiz Type 
                <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
              </label>
              <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={requestData.quiz_type_id}
                    onChange={handleChange}
                    name="quiz_type_id"
                    MenuProps={MenuProps}
                    InputLabelProps={{ shrink: false }}
                    input={<OutlinedInput label=" " />}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    className="customSelect"
                  >
                    {quizTypesIds.map((quizType) => (
                      <MenuItem key={quizType.id} value={quizType.id}>
                        {quizType.id}{" "}
                        {`[ ${quizType.name ? quizType.name : "No Title"} ]`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
               {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
