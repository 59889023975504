import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialRequestId = {
  name: "",
  age: null,
  points: null,
  gender: null,
  user_id: null,
};
export default function BasicModal({
  updatePopUp,
  setUpdatePopUp,
  subUser,
  id,
  getSubUsers,
}) {
  let authToken = localStorage.getItem("userToken");
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatars, setSelectedAvatars] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [subSubjects, setSubSubjects] = useState([]);
  const [selectedSubSubjects, setSelectedSubSubjects] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [quizs, setQuizs] = useState([]);
  const [selectedQuizs, setSelectedQuizs] = useState([]);
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [users, setUsers] = useState([]);
  /*------------------------Get  Avatars Ids--------------------------------------------------*/
  const handleAvatarIds = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAvatars(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectedAvatarIds = (event) => {
    setSelectedAvatars(event.target.value);
  };
  const getAvatarsid = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/avatar",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAvatars(data.data);
  };
  let avatarIds = avatars.map((avatar) => ({
    id: avatar.id,
    name: avatar.name,
  }));
  useEffect(() => {
    getAvatarsid();
  }, []);
  //End of Avatar Ids
  /*------------------------Get  Certificates Ids--------------------------------------------------*/
  const handleCertificatesIds = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCertificates(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeSelectedCertificatesIds = (event) => {
    setSelectedCertificates(event.target.value);
  };
  const getCertificatesIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/certificate",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCertificates(data.data);
  };
  let certificateIds = certificates.map((certificate) => ({
    id: certificate.id,
    title_two: certificate.title_two,
  }));
  useEffect(() => {
    getCertificatesIds();
  }, []);
  //End of Certificates Ids
  /*------------------------Get  Subjects Ids--------------------------------------------------*/
  const handleSubjectsIds = (event) => {
    const {
      target: { value },
    } = event;
    setSubSubjects(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectedSubjectids = (event) => {
    setSelectedSubjects(event.target.value);
  };
  const getSubjectsIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/subject",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubjects(data.data);
  };
  let subjectIds = subjects.map((subject) => ({
    id: subject.id,
    name: subject.name,
  }));
  useEffect(() => {
    getSubjectsIds();
  }, []);
  //End of Subjects Ids
  /*------------------------Get  sub Subjects Ids--------------------------------------------------*/
  const handleSubSubjectsIds = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubSubjects(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeSelectedSubSubjectids = (event) => {
    setSelectedSubSubjects(event.target.value);
  };
  const getSubSubjectsIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/sub-subject",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubSubjects(data.data);
  };
  let subSubjectIds = subSubjects.map((subSubject) => ({
    id: subSubject.id,
    name: subSubject.name,
  }));
  useEffect(() => {
    getSubSubjectsIds();
  }, []);
  //End of sub Subjects Ids

  /*------------------------Get  lESSONS Ids--------------------------------------------------*/
  const handleLessonsIds = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLessons(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectedLessonsIds = (event) => {
    setSelectedLessons(event.target.value);
  };
  const getLessonIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/lesson",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setLessons(data.data);
  };
  let lessonIds = lessons.map((lesson) => ({
    id: lesson.id,
    name: lesson.name,
  }));
  useEffect(() => {
    getLessonIds();
  }, []);
  //End of lessons Ids

  /*------------------------Get  Quizs Ids--------------------------------------------------*/
  const handleQuizsIds = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedQuizs(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeSelectedQuizsIds = (event) => {
    setSelectedQuizs(event.target.value);
  };
  const getQuizsIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/quiz",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setQuizs(data.data);
  };
  let quizsIds = quizs.map((quiz) => ({
    id: quiz.id,
    name: quiz.name,
  }));
  useEffect(() => {
    getQuizsIds();
  }, []);
  //End of quizs Ids

  const handleOpen = () => setUpdatePopUp(true);
  const handleClose = () => {
    setUpdatePopUp(false);
    setSelectedAvatars([]);
    setSelectedAvatars([]);
    setSelectedCertificates([]);
    setSelectedLessons([]);
    setSelectedQuizs([]);
    setSelectedSubSubjects([]);
    setSelectedSubjects([]);
    setSubSubjects([]);
    setRequestData({...initialRequestId});
    setApiErrors(null);
    setErrorList(null);
  };

  const [requestData, setRequestData] = useState({
    ...initialRequestId,
  });
console.log(subUser)
  useEffect(() => {
    if (subUser) {
      let { name, age, points, gender,user,avatars=[],quizs=[],sub_subjects=[] ,certificates=[],subjects=[],lessons=[]} = subUser;
      setRequestData({ name, age, points, gender,user_id:user?.id||0 });
      setSelectedAvatars(
        avatars.map((avatar) => {
          return avatar.id;
        })
      );
      setSelectedQuizs(
        quizs.map((quiz) => {
          return quiz.id;
        })
      );
      setSelectedSubSubjects(
        sub_subjects.map((subSubject) => {
          return subSubject.id;
        })
      );
      setSelectedCertificates(
        certificates.map((certificate) => {
          return certificate.id;
        })
      );
      setSelectedSubjects(
        subjects.map((subject) => {
          return subject.id;
        })
      );
      setSelectedLessons(
        lessons.map((lesson) => {
          return lesson.id;
        })
      );
    }

  }, [subUser]);
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };

  const validateSubUser = (requestData) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
      points: Joi.number().required().messages({
        "number.empty": `"points" can't be an empty field`,
        "any.required": `"points" is a required field`,
      }),
      age: Joi.number().required().messages({
        "number.empty": `"age" can't be an empty field`,
        "any.required": `"age" is a required field`,
      }),
      gender: Joi.string().required().messages({
        "string.empty": `"gender" can't be an empty field`,
        "any.required": `"gender" is a required field`,
      }),
      user_id: Joi.number().required().messages({
        "number.empty": `"user_id" can't be an empty field`,
        "any.required": `"user_id" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validateSubUser(requestData);
    const formData = new FormData();
    formData.append("name", requestData.name);
    formData.append("age", requestData.age);
    formData.append("gender", requestData.gender);
    formData.append("points", requestData.points);
    formData.append("user_id", requestData.user_id);
    for (var i = 0; i < selectedAvatars.length; i++) {
      formData.append(`avatar_ids[${i}]`, selectedAvatars[i]);
    }
    for (var i = 0; i < selectedCertificates.length; i++) {
      formData.append(`true_false_question_ids[${i}]`, selectedCertificates[i]);
    }
    for (var i = 0; i < selectedSubjects.length; i++) {
      formData.append(`subject_ids[${i}]`, selectedSubjects[i]);
    }

    for (var i = 0; i < selectedSubSubjects.length; i++) {
      formData.append(`sub_subject_ids[${i}]`, selectedSubSubjects[i]);
    }
    for (var i = 0; i < selectedLessons.length; i++) {
      formData.append(`lesson_ids[${i}]`, selectedLessons[i]);
    }
    for (var i = 0; i < selectedQuizs.length; i++) {
      formData.append(`quiz_ids[${i}]`, selectedQuizs[i]);
    }
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/sub-user/${id}/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getSubUsers();
          setRequestData({});
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  //get user Id
  const getUserIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/user",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setUsers(data.data);
  };
  let usersIds = users.map((user) => ({
    id: user.id,
    name: user.name,
  }));
  useEffect(() => {
    getUserIds();
  }, []);
  return (
    <div>
      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Update Row
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
          <div className="d-flex mb-3">
            <div className=" w-50">
              <label htmlFor="arVideo" className="form-label">
                Name
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control"
                type="text"
                id="arVideo"
                name="name"
                placeholder={subUser.name}
                value={requestData.name}
                onChange={handleChange}
              />
            </div>
            <div className=" w-50 ms-3 ">
              <label htmlFor="arVideo" className="form-label">
                Age
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control"
                type="number"
                id="arVideo"
                name="age"
                placeholder={subUser.age}
                value={requestData.age}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="mb-2 w-50">
              <label htmlFor="arVideo" className="form-label">
                User
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={requestData.user_id}
                  onChange={handleChange}
                  name="user_id"
                  MenuProps={MenuProps}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  className="customSelect"
                >
                  {usersIds.map((userId) => (
                    <MenuItem key={userId.id} value={userId.id}>
                      {userId.id} {`[ ${userId.name} ]`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="mb-2 w-50 ms-3">
              <label htmlFor="arVideo" className="form-label">
                Points
                <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
              </label>
              <input
                className="form-control"
                type="number"
                id="arVideo"
                name="points"
                placeholder={subUser.points}
                value={requestData.points}
                onChange={handleChange}
              />
            </div>
          </div>
          <label htmlFor="arLang" className="form-label">
            Gender
            <span className="required"> * </span>
            <span className="hide">This is a Required Feild</span>
          </label>

          <div className="input-group mb-3">
            <select
              className="form-select"
              id="arLang"
              name="gender"
              value={requestData.gender}
              onChange={handleChange}
            >
              <option defaultValue>Choose...</option>
              <option value={"boy"}>boy</option>
              <option value={"girl"}>girl</option>
            </select>
            <label className="input-group-text" htmlFor="inputGroupSelect02">
              Options
            </label>
          </div>
          <div className="d-flex">
            <div className="w-50  mb-2">
              <FormControl sx={{ width: "100%" }}>
                <label htmlFor="arLang" className="form-label">
                  Avatars
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedAvatars}
                  onChange={(e) => {
                    handleAvatarIds(e);
                    handleChangeSelectedAvatarIds(e);
                  }}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="customSelect"
                >
                  {avatarIds.map((avatar) => (
                    <MenuItem key={avatar.id} value={avatar.id}>
                      <Checkbox
                        checked={selectedAvatars.indexOf(avatar.id) > -1}
                        style={{
                          color: "rgba(125, 82, 179, 1)",
                        }}
                        iconStyle={{ fill: "white" }}
                      />
                      <ListItemText
                        primary={`${avatar.id} [ ${avatar.name} ]`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-50 ms-3 mb-2">
              <FormControl sx={{ width: "100%" }}>
                <label htmlFor="arLang" className="form-label">
                  Quizs
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedQuizs}
                  onChange={(e) => {
                    handleQuizsIds(e);
                    handleChangeSelectedQuizsIds(e);
                  }}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="customSelect"
                >
                  {quizsIds.map((quiz) => (
                    <MenuItem key={quiz.id} value={quiz.id}>
                      <Checkbox
                        checked={selectedQuizs.indexOf(quiz.id) > -1}
                        style={{
                          color: "rgba(125, 82, 179, 1)",
                        }}
                        iconStyle={{ fill: "white" }}
                      />
                      <ListItemText primary={`${quiz.id} [ ${quiz.name} ]`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex">
            <div className="w-50  mb-2">
              <FormControl sx={{ width: "100%" }}>
                <label htmlFor="arLang" className="form-label">
                  Certificates
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedCertificates}
                  onChange={(e) => {
                    handleCertificatesIds(e);
                    handleChangeSelectedCertificatesIds(e);
                  }}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="customSelect"
                >
                  {certificateIds.map((certId) => (
                    <MenuItem key={certId.id} value={certId.id}>
                      <Checkbox
                        checked={selectedCertificates.indexOf(certId.id) > -1}
                        style={{
                          color: "rgba(125, 82, 179, 1)",
                        }}
                        iconStyle={{ fill: "white" }}
                      />
                      <ListItemText
                        primary={`${certId.id} [ ${
                          certId.title_two ? certId.title_two : "No Title"
                        } ]`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-50 ms-3 mb-2">
              <FormControl sx={{ width: "100%" }}>
                <label htmlFor="arLang" className="form-label">
                  Subjects
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedSubjects}
                  onChange={(e) => {
                    handleSubjectsIds(e);
                    handleChangeSelectedSubjectids(e);
                  }}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="customSelect"
                >
                  {subjectIds.map((subject) => (
                    <MenuItem key={subject.id} value={subject.id}>
                      <Checkbox
                        checked={subSubjects.indexOf(subject.id) > -1}
                        style={{
                          color: "rgba(125, 82, 179, 1)",
                        }}
                        iconStyle={{ fill: "white" }}
                      />
                      <ListItemText
                        primary={`${subject.id} [ ${subject.name} ]`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex">
            <div className="w-50  mb-2">
              <FormControl sx={{ width: "100%" }}>
                <label htmlFor="arLang" className="form-label">
                  Sub-Subjects
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedSubSubjects}
                  onChange={(e) => {
                    handleSubSubjectsIds(e);
                    handleChangeSelectedSubSubjectids(e);
                  }}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="customSelect"
                >
                  {subSubjectIds.map((subSubject) => (
                    <MenuItem key={subSubject.id} value={subSubject.id}>
                      <Checkbox
                        checked={
                          selectedSubSubjects.indexOf(subSubject.id) > -1
                        }
                        style={{
                          color: "rgba(125, 82, 179, 1)",
                        }}
                        iconStyle={{ fill: "white" }}
                      />
                      <ListItemText
                        primary={`${subSubject.id} [ ${subSubject.name} ]`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-50 ms-3 mb-2">
              {/* <FormControl sx={{ width: "100%" }}>
                <label htmlFor="arLang" className="form-label">
                  Lessons Ids
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedLessons}
                  onChange={(e) => {
                    handleLessonsIds(e);
                    handleChangeSelectedLessonsIds(e);
                  }}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="customSelect"
                >
                  {lessonsIds.map((id) => (
                    <MenuItem key={id} value={id}>
                      <Checkbox
                        checked={lessonShow.indexOf(id) > -1}
                        style={{
                          color: "rgba(125, 82, 179, 1)",
                        }}
                        iconStyle={{ fill: "white" }}
                      />
                      <ListItemText primary={id} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <FormControl sx={{ width: "100%" }}>
                <label htmlFor="arLang" className="form-label">
                  Lessons
                </label>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedLessons}
                  onChange={(e) => {
                    handleLessonsIds(e);
                    handleChangeSelectedLessonsIds(e);
                  }}
                  InputLabelProps={{ shrink: false }}
                  input={<OutlinedInput label=" " />}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className="customSelect"
                >
                  {lessonIds.map((lesson) => (
                    <MenuItem key={lesson.id} value={lesson.id}>
                      <Checkbox
                        checked={selectedLessons.indexOf(lesson.id) > -1}
                        style={{
                          color: "rgba(125, 82, 179, 1)",
                        }}
                        iconStyle={{ fill: "white" }}
                      />
                      <ListItemText
                        primary={`${lesson.id} [ ${lesson.name} ]`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
