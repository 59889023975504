import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import axios from "axios";
import Switch from "@mui/material/Switch";
import ContactUsModal from "../ContactUsModal/ContactUsModal";
import ViewModal from "../ViewModal/ViewModal";
import DeleteModal from "../DeleteModal/DeleteModal";
import LinearProgress from "@mui/material/LinearProgress";
import useAxios from "../../../providers/useAxios";
const label = { inputProps: { "aria-label": "Switch demo" } };
export default function ContactUsTable() {
  let authToken = localStorage.getItem("userToken");
  const [contactUsList, setContactUsList] = useState([]);
  const [contactUs, setContactUs] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState();
  const [loading, setloading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getContactUs = async () => {
    let { data } = await axios
      .get("https://dashboard.kunozworld.com/api/dashboard/contact_us", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setContactUsList(data.data);

  };

  useEffect(() => {
    getContactUs();
  }, []);
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };

  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/contact_us/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setContactUs(data.data[0]);
  };
  const deleteRow = (id) => {
    const temp = [...contactUs];
    axios.delete(
      `https://dashboard.kunozworld.com/api/dashboard/contact_us/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setContactUsList(temp);
    getContactUs();
    setDeletePopUp(!deletePopUp);
  };

  return (
    <div className="">
      <ContactUsModal getContactUs={getContactUs} />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        contactUs={contactUs}
        setContactUs={setContactUs}
      />
      <DeleteModal
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getContactUs={getContactUs}
        deleteRow={deleteRow}
        id={id}
      />

      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Message
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Subject
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Email
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactUsList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>

                      <TableCell align="center">{row.name}</TableCell>

                      <TableCell align="center">{row.message}</TableCell>
                      <TableCell align="center">{row.subject}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">
                        {row.status === 1 ? (
                          <Switch
                            {...label}
                            defaultChecked
                            disabled
                            color="secondary"
                          />
                        ) : (
                          <Switch {...label} disabled />
                        )}
                      </TableCell>

                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={contactUsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
