import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import AboutUsHeader from "../AboutUsHeader/AboutUsHeader";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initialRequestId = {
  title: "",
  subject: "",
  id: "",
  language: "",
  image_one: null,
  image_two: null,
  image_three: null,
  image_four: null,
};

export default function UpdatePopUp({ id, getAboutUs, aboutUs }) {
  const title = "About Us";
  const desc = "Edit About Us";
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [isLoading, setloading] = useState(false);
  const [requestData, setRequestData] = useState({ ...initialRequestId });
  const handleOpen = () => setOpen(true);
  const handleImageOne = (e) => {
    setRequestData({
      ...requestData,
      image_one: e.target.files[0],
    });
  };
  const handleImageTwo = (e) => {
    setRequestData({
      ...requestData,
      image_two: e.target.files[0],
    });
  };
  const handleImageThree = (e) => {
    setRequestData({
      ...requestData,
      image_three: e.target.files[0],
    });
  };
  const handleImageFour = (e) => {
    setRequestData({
      ...requestData,
      image_four: e.target.files[0],
    });
  };
  useEffect(() => {
    if (aboutUs) {
      let { title, subject, language } = aboutUs;
      setRequestData({ title, subject, language });
    }
  }, [aboutUs]);
  //handle changes in all inputs except image (file)
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleClose = () => {
    setOpen(false);
    setUpdatePopUp(false);
    setRequestData({ ...initialRequestId });
  };
  let authToken = localStorage.getItem("userToken");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", requestData.title);
    formData.append("subject", requestData.subject);
    formData.append("language", requestData.language);
    requestData.image_one &&
      formData.append("image_one", requestData.image_one);
    requestData.image_two &&
      formData.append("image_two", requestData.image_two);
    requestData.image_three &&
      formData.append("image_three", requestData.image_three);
    requestData.image_four &&
      formData.append("image_four", requestData.image_four);
    if (id === 2) {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/about_us/2/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getAboutUs(2);
          setRequestData("");
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        getAboutUs(2);
        setloading(false);
        console.log("error");
      }
    } else if (id === 1) {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/about_us/1/update`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setUpdatePopUp(false);
          setloading(false);
          getAboutUs(1);
          setRequestData("");
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        getAboutUs(1);
        setloading(false);
        console.log("error");
      }
    }
  };

  return (
    <div>
      <AboutUsHeader
        title={title}
        desc={desc}
        handleOpen={handleOpen}
        handleUpdate={handleUpdate}
      />

      <Modal
        open={updatePopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", height: "100%" }}
        disableScrollLock={true}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-2"
          >
            Update About Us
          </Typography>
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Title
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <textarea
              className="form-control"
              type="text"
              id="title"
              name="title"
              value={requestData.title}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Subject
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <textarea
              className="form-control"
              type="text"
              id="subject"
              name="subject"
              value={requestData.subject}
              onChange={handleChange}
            />
          </div>
          <label htmlFor="name" className="form-label">
            Languages
            <span className="required"> * </span>
            <span className="hide">This is a Required Feild</span>
          </label>
          <div className="input-group mb-3">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="language"
              value={requestData.language}
              onChange={handleChange}
            >
              <option defaultValue>Choose...</option>
              <option value="ar">ar</option>
              <option value="en">en</option>
            </select>
            <label className="input-group-text" htmlFor="inputGroupSelect02">
              Options
            </label>
          </div>
          <div className="d-flex">
            <div className="mb-2 me-3">
              <label htmlFor="formFile" className="form-label">
                Image One
              </label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                name="image_one"
                onChange={handleImageOne}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="formFile" className="form-label">
                Image Two
              </label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                name="image_two"
                onChange={handleImageTwo}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="mb-2 me-3">
              <label htmlFor="formFile" className="form-label">
                Image Three
              </label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                name="image_three"
                onChange={handleImageThree}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="formFile" className="form-label">
                Image Four
              </label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                name="image_four"
                onChange={handleImageFour}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              onClick={handleFormSubmit}
              className="btn btn-light addBtn"
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Update"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
