import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Joi from "joi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function BasicModal({ getCountries }) {
  const title = "Countries";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Country";
  const [open, setOpen] = React.useState(false);
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setloading(false);
    setOpen(false)
    setRequestData({})
    setApiErrors([]);
    setErrorList([]);
  };
  const [requestData, setRequestData] = useState({
    name: "",
    code: "",
    language: "",
    image: null,
   
  });


  //handle image file
  const handleFile = (e) => {
    setRequestData({ ...requestData, image: e.target.files[0] });
    // setFile(e.target.files[0]);
  };

  //handle changes in all inputs except image (file)
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };

  //post new country to API
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let valRes = validatenewCountry(requestData);
    const formData = new FormData();
    formData.append("name", requestData.name);
    formData.append("code", requestData.code);
    formData.append("language", requestData.language);
    formData.append("image", requestData.image);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
       setloading(true);
        let { data } = await axios.post(
          "https://dashboard.kunozworld.com/api/dashboard/country"
        ,
        formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          })
           
        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getCountries();
          setRequestData("");
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  const validatenewCountry = (country) => {
    console.log(country);
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
      code: Joi.string().required().messages({
        "string.empty": `"code" can't be an empty field`,
        "any.required": `"code" is a required field`,
      }),
      language: Joi.string().required().messages({
        "string.empty": `"language" can't be an empty field`,
        "any.required": `"language" is a required field`,
      }),
      image: Joi.object().required().messages({
        "object.base": `"image" can't be an empty field`,
      }),
    });
    return schema.validate(country, { abortEarly: false });
  };
  useEffect(() => {
    setApiErrors([]);
    setErrorList([]);
   }, [requestData]);
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Country
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
              <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              value={requestData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="code" className="form-label">
              Code
              <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="code"
              name="code"
              value={requestData.code}
              onChange={handleChange}
            />
          </div>

          <label htmlFor="name" className="form-label">
            Languages
            <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
          </label>
          <div className="input-group mb-3">
            <select
              className="form-select"
              id="inputGroupSelect02"
              name="language"
              value={requestData.language}
              onChange={handleChange}
            >
              <option defaultValue>Choose...</option>
              <option value="ar">ar</option>
              <option value="en">en</option>
            </select>
            <label className="input-group-text" htmlFor="inputGroupSelect02">
              Options
            </label>
          </div>

          <div className="mb-2">
            <label htmlFor="formFile" className="form-label">
              Image
              <span className="required"> * </span>
                <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="formFile"
              name="image"
              onChange={handleFile}
            />
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
                 {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
