import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState, useEffect } from "react";
import DeletePopUp from "../DeletePopUpModal/DeletePopUp";
import axios from "axios";
import Modal from "../AddNewCountryModal/Modal";
import ViewModal from "../ViewModal/ViewModal";
import UpdateModal from "../UpdateModal/UpdateModal";
import LinearProgress from "@mui/material/LinearProgress";
import Governments from '../Govrnments/Govrnments';
import Cities from '../Cities/Cities'
export default function CountryTable() {
  let authToken = localStorage.getItem("userToken");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [countryId, setId] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setloading] = useState(true);
  const [governments, setGovernments] = useState([]);
  const [governmentPopUp, setGovernmetPopUp] = useState(false);
  const [cities,setCities]= useState([]);
  const [cityPopUp, setCityPopUp] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getCountries = async () => {
    let { data } = await axios
      .get("https://dashboard.kunozworld.com/api/dashboard/country", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      })
      .finally(() => {
        setloading(false);
      });
    setCountries(data.data);
    //     const { response, fetchData, loading } = getData;
    //     if (loading == false) {
    //       fetchData();
    //       setCountries(response.data);
    // }
  };

  useEffect(() => {
    getCountries();
  }, []);
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleGovernments = () => {
    setGovernmetPopUp(!governmentPopUp);
  };
  const handleCities = () => {
    setCityPopUp(!cityPopUp);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/country/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCountry(data.data[0]);
    setGovernments(data.data[0].governments);
    setCities(data.data[0].cities);
  };
  const deleteRow = (id) => {
    const temp = [...countries];
    axios.delete(
      `https://dashboard.kunozworld.com/api/dashboard/country/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCountries(temp);
    getCountries();
    setDeletePopUp(!deletePopUp);
  };

  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/country/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setCountry(data.data[0]);
  };
  return (
    <div className="">
      <Modal getCountries={getCountries} />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getCountries={getCountries}
        deleteRow={deleteRow}
        id={countryId}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        country={country}
        setCountry={setCountry}
      />
      <UpdateModal
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        country={country}
        setCountry={setCountry}
        id={countryId}
        getCountries={getCountries}
      />
       <Governments 
        governmentPopUp={governmentPopUp}
        setGovernmetPopUp={setGovernmetPopUp}
        governments={governments}
      />
       <Cities 
        cityPopUp={cityPopUp}
        setCityPopUp={setCityPopUp}
        cities={cities}
      />
      {/* {allLoading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )} */}
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Code
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Governments
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Cities
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Language
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countries
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>

                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        <img src={row.image} className="flag" alt="not found" />
                      </TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleGovernments();
                              viewRow(row.id);
                            }}
                          >
                            Governments
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleCities();
                              viewRow(row.id);
                            }}
                          >
                            Cities
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">{row.language}</TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            handleUpdate();
                            viewBeforeUpdate(row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={countries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
