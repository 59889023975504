
import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Joi from "joi";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getActivites }) {
  const title = "Activites";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New Activity";
  const [open, setOpen] = useState(false);
  const [errorList, setErrorList] = useState(null);
  const [arErrors, setArErrors] = useState(null);
  const [enErrors, setEnErrors] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [languageAr, setLanguagesAr] = useState({
    name: "",
    url: "",
    image_one: null,
    image_two: null,
  });
  const [languageEn, setLanguagesEn] = useState({
    name: "",
    url: "",
    image_one: null,
    image_two: null,
  });
  const [requestData, setRequestData] = useState({
    points: 0,
    lesson_id: null,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setApiErrors(null);
    setErrorList(null);
    setRequestData({});
    setLanguagesAr({});
    setLanguagesEn({});
  };

  const handleImageOneAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      image_one: e.target.files[0],
    });
  };
  const handleImagTwoAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      image_two: e.target.files[0],
    });
  };
  const handleImageOneEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      image_one: e.target.files[0],
    });
  };
  const handleImageTwoEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      image_two: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeAr = (event) => {
    setLanguagesAr({
      ...languageAr,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeEn = (event) => {
    setLanguagesEn({
      ...languageEn,
      [event.target.name]: event.target.value,
    });
  };

  const handleFilesAr = (e) => {
    setLanguagesAr({
      ...languageAr,
      [e.target.name]: e.target.files[0],
    });
  };
  const handleFilesEn = (e) => {
    setLanguagesEn({
      ...languageEn,
      [e.target.name]: e.target.files[0],
    });
    console.log(e.target.files[0]);
  };
  const validateArabic = (ar) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"arabic name" can't be an empty field`,
        "any.required": `"arabic name" is a required field`,
      }),
      url: Joi.object().required().messages({
        "object.base": `"url" can't be empty`,
      }),
      image_one: Joi.object().required().messages({
        "object.base": `"arabic image1" can't be an empty field`,
      }),
      image_two: Joi.object().required().messages({
        "object.base": `"arabic image2" can't be an empty field`,
      }),
    
    });

    return schema.validate(ar, { abortEarly: false });
  };
  const validateEnglish = (en) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"english name" can't be empty`,
        "any.required": `"english name" is a required field`,
      }),
      url: Joi.object().required().messages({
        "object.base": `"url" can't be empty`,
      }),
      image_one: Joi.object().required().messages({
        "object.base": `"english image1" can't be empty`,
      }),
      image_two: Joi.object().required().messages({
        "object.base": `"english image2" can't be empty`,
      }),
     
    });

    return schema.validate(en, { abortEarly: false });
  };

  const validateActivity = (requestData) => {
    let schema = Joi.object({
      points: Joi.optional().allow(null),
      lesson_id: Joi.number().required().messages({
        "number.empty": `"lesson_id" can't be an empty field`,
        "any.required": `"lesson_id" is a required field`,
      }),
    });

    return schema.validate(requestData, { abortEarly: false });
  };
  const handleFormSubmit = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    let valRes = validateActivity(requestData);
    let valAr = validateArabic(languageAr);
    let valEn = validateEnglish(languageEn);

    const formData = new FormData();
    requestData.points&&
    formData.append("points", requestData.points);
    formData.append("lesson_id", requestData.lesson_id);
    formData.append("languages[0][name]", languageAr.name);
    formData.append("languages[0][url]", languageAr.url);
    formData.append("languages[0][image_one]", languageAr.image_one);
    formData.append("languages[0][image_two]", languageAr.image_two);
    formData.append("languages[0][language]", 'ar');
    formData.append("languages[1][name]", languageEn.name);
    formData.append("languages[1][url]", languageEn.url);
    formData.append("languages[1][image_one]", languageEn.image_one);
    formData.append("languages[1][image_two]", languageEn.image_two);
    formData.append("languages[1][language]", 'en');

    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else if (valAr.error || valEn.error) {
      setArErrors(valAr.error.details);

      setEnErrors(valEn.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/activity`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getActivites();
          setRequestData({});
          setLanguagesAr({});
          setLanguagesEn({});
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
        console.log(error.response.data.errors);
      }
    }
  };
  useEffect(() => {
    setApiErrors(null);
    setErrorList(null);
  }, [requestData]);
  useEffect(() => {
    setArErrors(null);
    setEnErrors(null);
  }, [languageAr]);
  useEffect(() => {
    setEnErrors(null);
    setArErrors(null);
  }, [languageEn]);
  //get lessons Id
  const getLessonsIds = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/lesson",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setLessons(data.data);
  };
  let lessonsIds = lessons.map((lesson) => ({
    id: lesson.id,
    name: lesson.name,
  }));
  useEffect(() => {
    getLessonsIds();
  }, []);
  //end of lesson  id
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll", overflowX: "visible", height: "100%" }}
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New Activity
          </Typography>
          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}

          <div className="d-flex justify-content-between w-100">
            <div className="w-50 me-2">
              {arErrors &&
                arErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
            <div className="w-50">
              {enErrors &&
                enErrors.map((error, index) => {
                  return (
                    <div key={index} className="alert alert-danger">
                      {error.message}
                    </div>
                  );
                })}
            </div>
          </div>

          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}

          <div className="d-flex w-100">
            <div className="w-50">
              <p className="mb-2 text-center  fw-bold">Arabic</p>
              <div className="mb-2">
                <label htmlFor="arTitle" className="form-label">
                  Name
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="arTitle"
                  name="name"
                  value={languageAr.name}
                  onChange={handleChangeAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="arUrl" className="form-label">
                  Url
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="arUrl"
                  name="url"
                  accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  onChange={handleFilesAr}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="image" className="form-label">
                  Image One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image_one"
                  onChange={handleImageOneAr}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="image" className="form-label">
                  Image Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image_two"
                  onChange={handleImagTwoAr}
                />
              </div>

              
            </div>
            <div className="ms-3 w-50">
              <p className="mb-2 text-center  fw-bold">English</p>

              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Name<span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="enTitle"
                  name="name"
                  value={languageEn.name}
                  onChange={handleChangeEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="enTitle" className="form-label">
                  Url
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="enTitle"
                  name="url"
                  accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  onChange={handleFilesEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="image" className="form-label">
                  Image One
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image_one"
                  onChange={handleImageOneEn}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="image" className="form-label">
                  Image Two
                  <span className="required"> * </span>
                  <span className="hide">This is a Required Feild</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image_two"
                  onChange={handleImageTwoEn}
                />
              </div>
            
            </div>
          </div>

          <div className="mb-2">
            <label htmlFor="arTitle" className="form-label">
              Points
            </label>
            <input
              className="form-control"
              type="text"
              id="arTitle"
              name="points"
              value={requestData.points}
              onChange={handleChange}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="arHeader" className="form-label">
              Lesson
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={requestData.lesson_id}
                onChange={handleChange}
                name="lesson_id"
                MenuProps={MenuProps}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                className="customSelect"
              >
                {lessonsIds.map((lesson) => (
                  <MenuItem key={lesson.id} value={lesson.id}>
                    {lesson.id} {`[ ${lesson.name ? lesson.name : "No Title"} ]`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}{" "}
            </button>
          </div>
          <div className="closeButn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
