import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import TableHeader from "../../TableHeader/TableHeader";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Joi from "joi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "20%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function BasicModal({ getCites }) {
  const title = "Cities";
  let authToken = localStorage.getItem("userToken");
  const desc = "Add New City";
  const [open, setOpen] = React.useState(false);
  const [errorList, setErrorList] = useState(null);
  const [apiErrors, setApiErrors] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [governments, setGovernments] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRequestData("");
    setErrorList(null);
    setApiErrors(null);
    setloading(false);
  };
  const [requestData, setRequestData] = useState({
    name: "",
    government_id: "",
  });
  useEffect(() => {
    setErrorList(null);
    setApiErrors(null);
  }, [requestData]);

  const handleChange = (event) => {
    setRequestData({
      ...requestData,
      [event.target.name]: event.target.value,
    });
  };

  //post new city to API
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", requestData.name);
    formData.append("government_id", requestData.government_id);
    let valRes = validatenewCity(requestData);
    if (valRes.error) {
      setErrorList(valRes.error.details);
    } else {
      try {
        setloading(true);
        let { data } = await axios.post(
          `https://dashboard.kunozworld.com/api/dashboard/city`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );

        if (data.message === "Successful") {
          setOpen(false);
          setloading(false);
          getCites();
          setRequestData("");
        }
      } catch (error) {
        setApiErrors(error.response.data.errors);
        setloading(false);
      }
    }


  };
  const validatenewCity = (city) => {
    let schema = Joi.object({
      name: Joi.string().required().messages({
        "string.empty": `"name" can't be an empty field`,
        "any.required": `"name" is a required field`,
      }),
      government_id: Joi.number().required().messages({
        "number.empty": `"id" can't be an empty field`,
        "any.required": `"id" is a required field`,
      }),
    });
    return schema.validate(city, { abortEarly: false });
  };
  //get government Id
  const getGovernments = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/government",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGovernments(data.data);
  };
  let governmentIds = governments.map((government) => ({
    id: government.id,
    name: government.name,
  }));
  useEffect(() => {
    getGovernments();
  }, []);
  //end of countries Id
  return (
    <div>
      <TableHeader title={title} desc={desc} handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center fw-bold mb-4"
          >
            Add New City
          </Typography>

          {errorList &&
            errorList.map((error, index) => {
              return (
                <div key={index} className="alert alert-danger">
                  {error.message}
                </div>
              );
            })}
          {apiErrors &&
            Object.keys(apiErrors).map((key) => (
              <div key={key} className="alert alert-danger">
                {key} must be {apiErrors[key][0].split(".")[1]}
              </div>
            ))}
          <div className="mb-2">
            <label htmlFor="name" className="form-label">
              Name
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>
            <input
              className="form-control"
              type="text"
              id="name"
              name="name"
              value={requestData.name}
              onChange={handleChange}
            />
          </div>
  
          <div className="mb-2">
            <label htmlFor="arVideo" className="form-label">
              Government
              <span className="required"> * </span>
              <span className="hide">This is a Required Feild</span>
            </label>

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={requestData.government_id}
                onChange={handleChange}
                name="government_id"
                MenuProps={MenuProps}
                InputLabelProps={{ shrink: false }}
                input={<OutlinedInput label=" " />}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                className="customSelect"
              >
                {governmentIds.map((government) => (
                  <MenuItem key={government.id} value={government.id}>
                    {government.id} {`[ ${government.name} ]`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              className="btn btn-light addBtn"
              onClick={handleFormSubmit}
            >
              {isLoading == true ? (
                <div className="d-flex align-items-center">
                  <span>Loading... </span>
                  <CircularProgress className="loadingIcon" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
          <div className="closeButtn">
            <CloseIcon className="closeIcon" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
