import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Paper from "@mui/material/Paper";
import ArabicModal from "../ArabicModal/ArabicModal";
import EnglishModal from "../EnglishModal/EnglishModal";
import SkillsIds from "../SkillsIds/SkillsIds";
import QuizsIds from "../QuizsIds/QuizsIds";
import AddGamesModal from "../AddGamesModal/AddGamesModal";
import DeletePopUp from "../DeletePopUp/DeletePopUpModal";
import ViewModal from "../ViewModal/ViewModal";
import UpdatePopUp from "../UpdatePopUp/UpdatePopUp";
import PermanentDeletePopUpTable from "../PermanentDelete/PermanentDeletePopUp/PermanentDeletePopUpTable/PermanentDeletePopUpTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import SubSubjectsIds from "../SubSubjects/SubSubjectsIds";
import CachedIcon from "@mui/icons-material/Cached";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const GamesTable = () => {
  let authToken = localStorage.getItem("userToken");
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [games, setGames] = useState([]);
  const [game, setGame] = useState([]);
  const [loading, setloading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [quizId, setQuizId] = useState();
  const [subUsers, setSubUsers] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [id, setId] = useState();
  const [ar, setAr] = useState({});
  const [en, setEn] = useState({});
  const [subSubjectPopUp, setSubSubjectPopUp] = useState(false);
  const [subSubject, setSubSubject] = useState([]);
  const [arabicPopUp, setArabicPopUp] = useState(false);
  const [englishPopUp, setEnglishPopUp] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [skillsPopUp, setSkillsPopUp] = useState(false);
  const [skills, setSkills] = useState([]);
  const [quizPopUp, setQuizPopUp] = useState(false);
  const [quizs, setQuizs] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({
    sub_user_id: null,
    age_group_id: null,
  });
  const clearFilter = () => {
    setFilterData({ sub_user_id: "", age_group_id: "" });
    getGames();
  };

  const [deletePermenant, setDeletePermenantPopUp] = useState(false);
  const handleDeletePermenant = () => {
    setDeletePermenantPopUp(!deletePermenant);
  };
  const handleSkills = () => {
    setSkillsPopUp(!skillsPopUp);
  };
  const handleSubSubject = () => {
    setSubSubjectPopUp(!subSubjectPopUp);
  };
  const handleQuizs = () => {
    setQuizPopUp(!quizPopUp);
  };
  const handleEn = () => {
    setEnglishPopUp(!englishPopUp);
  };
  const handleAr = () => {
    setArabicPopUp(!arabicPopUp);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDeleteAlert = () => {
    setDeletePopUp(!deletePopUp);
  };
  const handleView = () => {
    setViewPopUp(!viewPopUp);
  };
  const handleUpdate = () => {
    setUpdatePopUp(!updatePopUp);
  };
  const handleFilter = () => {
    setFilter(!filter);
  };
  const handleChangeFilters = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };

  const showFiltered = async () => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/lesson/?lesson_type_id=1&sub_user_id=${
        filterData.sub_user_id == null ? "" : filterData.sub_user_id
      }&age_group_id=${
        filterData.age_group_id == null ? "" : filterData.age_group_id
      }`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGames(data.data);
  };
  //get sub user Id
  const getSubUsers = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/sub-user",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setSubUsers(data.data);
  };
  let subUsersIds = subUsers.map((subUser) => ({
    id: subUser.id,
    name: subUser.name,
  }));
  useEffect(() => {
    getSubUsers();
  }, []);
  //get age group Id
  const getAgeGroups = async () => {
    let { data } = await axios.get(
      "https://dashboard.kunozworld.com/api/dashboard/age-group",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setAgeGroups(data.data);
  };
  let ageGroupIds = ageGroups.map((ageGroup) => ({
    id: ageGroup.id,
    name: ageGroup.name,
  }));
  useEffect(() => {
    getAgeGroups();
  }, []);
  const viewBeforeUpdate = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/lesson/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGame(data.data[0]);

    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const getGames = async () => {
    let { data } = await axios
      .get(
        `https://dashboard.kunozworld.com/api/dashboard/lesson/?lesson_type_id=1`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        }
      )
      .finally(() => {
        setloading(false);
      });
    setGames(data.data);
  };
  useEffect(() => {
    getGames();
  }, []);

  const viewLang = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/lesson/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    console.log(data.data[0].languages);
    setQuizs(await data.data[0].quiz);
    setSkills(await data.data[0].skills);
    setSubSubject(await data.data[0].sub_subject);
    setAr(await data.data[0].languages[0]);
    setEn(await data.data[0].languages[1]);
  };
  const viewRow = async (id) => {
    let { data } = await axios.get(
      `https://dashboard.kunozworld.com/api/dashboard/lesson/${id}/show`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGame(data.data[0]);
  };
  const deleteRow = (id) => {
    const temp = [...games];
    axios.delete(
      `https://dashboard.kunozworld.com/api/dashboard/lesson/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    setGames(temp);
    getGames();
    setDeletePopUp(false);
  };
  return (
    <div>
      <SkillsIds
        skillsPopUp={skillsPopUp}
        setSkillsPopUp={setSkillsPopUp}
        skills={skills}
      />
      <QuizsIds
        quizPopUp={quizPopUp}
        setQuizPopUp={setQuizPopUp}
        quizs={quizs}
      />
      <SubSubjectsIds
        subSubjectPopUp={subSubjectPopUp}
        setSubSubjectPopUp={setSubSubjectPopUp}
        subSubject={subSubject}
      />
      <ArabicModal
        ar={ar}
        arabicPopUp={arabicPopUp}
        setArabicPopUp={setArabicPopUp}
      />
      <EnglishModal
        en={en}
        englishPopUp={englishPopUp}
        setEnglishPopUp={setEnglishPopUp}
      />
      <AddGamesModal getGames={getGames} />
      <DeletePopUp
        deletePopUp={deletePopUp}
        setDeletePopUp={setDeletePopUp}
        getGames={getGames}
        deleteRow={deleteRow}
        id={id}
      />
      <ViewModal
        viewPopUp={viewPopUp}
        setViewPopUp={setViewPopUp}
        game={game}
        setGame={setGame}
      />
      <UpdatePopUp
        updatePopUp={updatePopUp}
        setUpdatePopUp={setUpdatePopUp}
        game={game}
        setGame={setGame}
        id={id}
        getGames={getGames}
        ar={ar}
        en={en}
      />

      <PermanentDeletePopUpTable
        deletePermenant={deletePermenant}
        setDeletePermenantPopUp={setDeletePermenantPopUp}
      />
      {loading && (
        <div className="mb-3">
          <LinearProgress color="secondary" />
        </div>
      )}

      <div className="deleteForeverDiv mb-3">
        <div className="filerDiv d-flex description">
          <FilterListIcon className={"filterIcon"} onClick={handleFilter} />

          <div
            className="describeFilter"
            onClick={() => {
              handleFilter();
            }}
          >
            Filter
          </div>
        </div>
        <div className=" ms-2 me-2 divider align-self-center"></div>
        <div className="delForever d-flex description">
          <DeleteForeverIcon
            className="deleteForever"
            onClick={() => {
              handleDeletePermenant();
            }}
          />
          <div
            className="describeDel"
            onClick={() => {
              handleDeletePermenant();
            }}
          >
            Trash
          </div>
        </div>
      </div>
      {/* <div className="d-flex mb-3 d-none"> */}
      <div className={`collapse mb-3  ${filter ? "d-flex" : "collapse"}`}>
        <div className="mb-2 w-25 me-2">
          <label htmlFor="arHeader" className="form-label">
            Sub User
          </label>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="sub_user_id"
              value={filterData.sub_user_id}
              onChange={handleChangeFilters}
              MenuProps={MenuProps}
              InputLabelProps={{ shrink: false }}
              input={<OutlinedInput label=" " />}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              className="customSelect form-control"
            >
              {subUsersIds.map((subUser) => (
                <MenuItem key={subUser.id} value={subUser.id}>
                  {subUser.id} {`[${subUser.name ? subUser.name : "No Title"}]`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="mb-2 w-25 me-2">
          <label htmlFor="arHeader" className="form-label">
            Age Group
          </label>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="age_group_id"
              value={filterData.age_group_id}
              onChange={handleChangeFilters}
              MenuProps={MenuProps}
              InputLabelProps={{ shrink: false }}
              input={<OutlinedInput label=" " />}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              className="customSelect form-control"
            >
              {ageGroupIds.map((ageGroup) => (
                <MenuItem key={ageGroup.id} value={ageGroup.id}>
                  {ageGroup.id}{" "}
                  {`[${ageGroup.name ? ageGroup.name : "No Title"}]`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 ">

          <FilterListIcon className={"filterIcon"} onClick={showFiltered} />
          <CachedIcon onClick={clearFilter} className={"reload"} />
        </div>
      </div>
      <Paper
        sx={{
          overflow: "hidden",
          margin: "auto",
          textAlign: "center",
          overflowX: "auto",
          width: "100%",
          mb: 3,
        }}
      >
        <TableContainer
          sx={{ maxHeight: 700, minWidth: 800 }}
          className="tableDesign"
        >
          <Table
            stickyHeader
            aria-label="sticky table "
            sx={{ width: 1, margin: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>#</TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                >
                  ID
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Points
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Lesson-Type Id
                </TableCell>

                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Sub-Subject
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Skills
                </TableCell>
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Languages
                </TableCell>
                
                <TableCell
                  style={{ fontWeight: "700", color: "rgba(255, 73, 73, 1)" }}
                  align="center"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        color: "red",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="smallBg">{index + 1}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>

                      <TableCell align="center">{row.points}</TableCell>
                      <TableCell align="center">
                        {row.lesson_type && row.lesson_type.id}
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleSubSubject();
                              viewLang(row.id);
                            }}
                          >
                            Sub-Subject
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleSkills();
                              viewLang(row.id);
                            }}
                          >
                            Skills
                          </button>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="btnDesign me-1"
                            onClick={() => {
                              handleEn();
                              viewLang(row.id);
                            }}
                          >
                            EN
                          </button>
                          <div className="divider align-self-center"></div>
                          <button
                            className="btnDesign ms-1"
                            onClick={() => {
                              handleAr();
                              viewLang(row.id);
                            }}
                          >
                            AR
                          </button>
                        </div>
                      </TableCell>

                    

                      <TableCell align="center">
                        <DeleteIcon
                          className="delIcon me-1"
                          onClick={() => {
                            setId(row.id);
                            handleDeleteAlert();
                          }}
                        />
                        <VisibilityIcon
                          className="viewIcon me-1"
                          onClick={() => {
                            handleView();
                            viewRow(row.id);
                          }}
                        />
                        <EditIcon
                          className="editIcon "
                          onClick={() => {
                            setId(row.id);
                            viewBeforeUpdate(row.id);
                            handleUpdate();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ textAlign: "center" }}
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={games.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default GamesTable;
